import { Link, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from "react";
import { TransactionSettingsModal } from "../modals/TransactionSettingsModal";
import { cleanNumberByKeyCodeInput, filterDecimals, getTokenBasedExcatValue, ifNanGiveZero, isCleanNumberInput, toggleBodyScroll, velarToast } from "../helper";
import { Config } from '../config';
import StakeContractService from '../services/StakeContractService';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountTokens, setTokenLoader } from '../store/slices/authSlice';
import { getTokens } from '../store/slices/swapSlice';
import { toast } from 'react-toastify';
import { useConnect } from '../hooks/useConnect';
import { LayoutContext } from '../contexts/LayoutContext';
import { getPools, setPoolLoader } from '../store/slices/poolSlice';
import { Input } from './Input';
import { SuccessfulModal } from '../modals/SuccessfulModal';
import { MyRewards } from '../components/MyRewards';
import Loader from './Loader';

const Tabs = {
    Stack: 'stack',
    UnStake: 'unstack',
    MyRewards: 'my-rewards'
}
export default function StakeUnstakeProcess() {
    const dispatch = useDispatch();
    const params = useParams();
    const swap = useSelector((x) => x.swap);
    const auth = useSelector((x) => x.auth);
    const [state, setState] = useState({ txMessage: '', successfulModal: false, balance: 0, totalStake: 0, str_balance: '', amount: 0, debalance: 0, deamount: 0, balance: 0,  tab: 'stack', stakingDetails: false,  transactionModal: false, transactionUrl: '', loader: false });
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const [ epoch, setEpoch ] = useState({ loading: false, epoch: '' });

    const getCurrentEpoch = async () => {
        if (state.tab === Tabs.MyRewards) {
            setEpoch({ loading: true, ...epoch });
            const currentEpoch = await StakeContractService.getCurrentEpoch();
            setEpoch({ loading: false, epoch: currentEpoch });
        }
    }

    useEffect(() => {
        // getCurrentEpoch();
        // const interval = setInterval(() => {
        //     getCurrentEpoch();
        // }, 10000);
        // return () => clearInterval(interval);
    }, [state.tab])

    useEffect(() => {
        (async () => {
            setState({ ...state, loader: true })
            const velar = auth.info.tokens.find((x) => x.contract === Config.ContractAddresses.Velar) || { str_balance: '0',  balance: 0 };
            const userShare = await StakeContractService.getUserSahre();
            const totalStake = await StakeContractService.getTotalStake();    
            console.log(userShare, totalStake);
            setState({ ...state, loader: false, totalStake: getTokenBasedExcatValue(totalStake, 'velar'), str_balance: velar?.str_balance, balance: getTokenBasedExcatValue(velar?.balance, 'velar'), debalance: getTokenBasedExcatValue(userShare, 'velar') });
        })()
    }, [auth.info.tokens.length])

    const aproveStack = async () => {
        try {
            const velarAddress = Config.ContractAddresses.Velar;
            const transactionPayload = {};
            transactionPayload.tokenSymbol = 'velar';
            transactionPayload.tokenContractAddress = velarAddress;
            transactionPayload.tokenQuantity = String(state.amount);

            const result = await StakeContractService.stack(state.amount, velarAddress, transactionPayload);
            let message = `Staking ${state.amount} VELAR`;
            velarToast(<div>
                <div className="title">Staking {state.amount} VELAR</div>
                <div className="timestamp">Less than 25 sec ago</div>
                <a className="link" target="_blank" href={`${Config.url}txid/${result.txId}?chain=${Config.name}`}>
                    View on explorer
                    <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
                </a>
            </div>);

            setTimeout(() => {
                setState({ ...state, transactionUrl: `${Config.url}txid/${result.txId}?chain=${Config.name}`, txMessage: message, successfulModal: true, amount: 0, deamount: 0, tab: 'stack' })
            }, 0);
        } catch (error) {
            console.log(error);
            // toast(<div>
            //     <div className="title">Staking some thing went wrong please try again.</div>
            // </div>);
        }
    }

    const aproveUnStack = async () => {
        try {
            const velarAddress = Config.ContractAddresses.Velar;
            const transactionPayload = {};
            transactionPayload.tokenSymbol = 'velar';
            transactionPayload.tokenContractAddress = velarAddress;
            transactionPayload.tokenQuantity = String(state.deamount);

            const result = await StakeContractService.unStack(state.deamount, velarAddress, transactionPayload);
            let message = `Unstaking ${state.deamount} VELAR`;
            velarToast(<div>
                <div className="title">Unstaking {state.deamount} VELAR</div>
                <div className="timestamp">Less than 25 sec ago</div>
                <a className="link" target="_blank" href={`${Config.url}txid/${result.txId}?chain=${Config.name}`}>
                    View on explorer
                    <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
                </a>
            </div>);

            setTimeout(() => {
                setState({ ...state, txMessage: message, transactionUrl: `${Config.url}txid/${result.txId}?chain=${Config.name}`, successfulModal: true, amount: 0, deamount: 0, tab: 'stack' });
            }, 0)

        } catch (error) {
            console.log(error);
            // toast(<div>
            //     <div className="title">UnStaking some thing went wrong please try again.</div>
            // </div>);
            // setState({ ...state })
        }
    }


    return (    
        <div className="stake-box">
            <div className="header">
                <div className="stacking-tabs flex items-center">
                    <button onClick={() => setState({ ...state, tab: Tabs.Stack })} className={(state.tab === Tabs.Stack ? 'active ' : '') + "tab-item" }>Stake</button>
                    <button onClick={() => setState({ ...state, tab: Tabs.UnStake })} className={(state.tab === Tabs.UnStake ? 'active ' : '') +  "tab-item" }>Unstake</button>
                    {/* <button onClick={() => setState({ ...state, tab: Tabs.MyRewards })} className={(state.tab === Tabs.MyRewards ? 'active ' : '') +  "tab-item" }>My Rewards</button> */}
                </div>
                { state.tab === Tabs.MyRewards && (
                    <div className='flex justify-between items-center'>
                        <span className='d-block' style={{ width: 8, height: 8, borderRadius: '100%', backgroundColor: 'greenyellow' }}></span>
                        <span className='text-gray relative flex items-center' style={{ marginLeft: 7, fontSize: 14 }}>Current Epoch { epoch.loading ? <Loader /> : epoch.epoch }</span>
                    </div>
                ) }
            </div>

            { state.tab == Tabs.Stack && (
                <div className="body">
                    <form>
                        <div className="enter-balance">
                            <img className="icon" src="/assets/icons/velar-search.svg" alt="" />
                            <Input 
                                value={state.amount || ''} 
                                onChange={(e) => {
                                    if (!isCleanNumberInput(e)) {
                                        setState({ ...state });
                                        return;
                                    }
                                    setState({ ...state, amount: e.target.value })
                                }} 
                                type="number" 
                                className="field" 
                                placeholder="Enter VELAR balance" />
                            <span onClick={(e) => setState({ ...state, amount: state.balance })} className="tag hand">Max</span>
                        </div>
                    </form>
                    <p className="velar-balance">VELAR Balance:  {  state.balance }</p>
                    <div className="will-recieve">
                        <p className="text">You will stake</p>
                        <p className="value">{ state.amount } VELAR</p>
                    </div>
                    <div className="staking-details my-6" data-accordion="collapse">
                        <button onClick={() => setState({ ...state, stakingDetails: !state.stakingDetails })}
                        type="button" className="text-pink-100 font-bold text-lg flex items-center justify-between w-full"
                        data-accordion-target="#staking-details" ariaaria-expanded="true" aria-controls="collapsible-details"
                    >
                        <span>Staking details</span>
                        <svg data-accordion-icon className={"w-6 h-6 shrink-0 " + (!state.stakingDetails && 'rotate-180')}
                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        </button>
                        <div id="staking-details" className={state.stakingDetails ? 'hidden' : ''}>
                        <div className="flex items-center justify-between mb-3">
                            <div className="key direction-row flex items-center">
                                <p className="text text-white">Staked</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Your total staked " + state.debalance.toFixed(6) + " in the pool"}
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value text-white">{ state.debalance.toFixed(6) } VELAR</p>
                        </div>
                        <div className="flex items-center justify-between mb-3">
                            <div className="key direction-row flex items-center">
                                <p className="text text-white">Total Staked</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Total " + state.totalStake.toFixed(6) + " staked in the pool"}
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value text-dark">{ state.totalStake.toFixed(6) } VELAR</p>
                        </div>
                        <div className="flex items-center justify-between mb-3">
                            <div className="key direction-row flex items-center">
                                <p className="text text-white">Pool Share</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Your " + (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) + "% share in the staking pool"} 
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value text-dark">{ (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) }%</p>
                        </div>
                        {/* <hr className="my-3" /> */}
                        {/* <div className="flex items-center justify-between">
                            <p className="key text-warning">You wil get</p> 
                            <p className="value text-warning">~$3.62 VELAR</p>
                        </div> */}
                        </div>
                    </div>
                    {!auth.isLoggedIn ? (
                        <button onClick={() => {
                            setLayoutState({
                                showSideBar: !layoutState.showSideBar,
                                showWalletSidebar: !layoutState.showWalletSidebar
                            })
                        }} className="btn-warning">Connect wallet</button>
                    ) : (
                        <>
                            { state.amount > state.balance ? (
                                <button className="btn-insufficient">Insufficient Balance</button>
                            ) : (
                                <button onClick={(e) => aproveStack()} disabled={(state.amount < 1)} className={((state.amount < 1) ? 'disabled ' : '') + "btn-warning"}>Approve Staking</button>
                            ) }
                        </>
                    )}


                    {/* <button className="btn-warning">Approve unstaking</button> */}
                </div>
            )}

            { state.tab == Tabs.UnStake && (
                <div className="body">
                    <form>
                        <div className="enter-balance">
                            <img className="icon" src="/assets/icons/velar-search.svg" alt="" />
                            <Input 
                                value={state.deamount || ''} 
                                step=".01"
                                onChange={(e) => {
                                    if (!isCleanNumberInput(e)) {
                                        setState({ ...state });
                                        return;
                                    }
                                    setState({ ...state, deamount: e.target.value })
                                }} 
                                type="number" className="field" placeholder="Enter VELAR balance" />
                            <span onClick={(e) => setState({ ...state, deamount: state.debalance })} className="tag hand">Max</span>
                        </div>
                    </form>
                    <p className="velar-balance">Staked amount: { state.debalance }</p>
                    <div className="will-recieve">
                        <p className="text">You will unstake</p>
                        <p className="value">{ state.deamount } VELAR</p>
                    </div>
                    <div className="staking-details my-6" data-accordion="collapse">
                        <button onClick={() => setState({ ...state, stakingDetails: !state.stakingDetails })}
                        type="button" className="text-pink-100 font-bold text-lg flex items-center justify-between w-full"
                        data-accordion-target="#staking-details" ariaaria-expanded="true" aria-controls="collapsible-details"
                    >
                        <span>Staking details</span>
                        <svg data-accordion-icon className={"w-6 h-6 shrink-0 " + (!state.stakingDetails ? 'rotate-180' : '')}
                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        </button>
                        <div id="staking-details" className={state.stakingDetails ? 'hidden' : ''}>
                        <div className="flex items-center justify-between mb-3">
                            <div className="key direction-row flex items-center">
                                <p className="text text-white">Staked</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Your total staked " + state.debalance + " in the pool"}
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value text-white">{ state.debalance } VELAR</p>
                        </div>
                        <div className="flex items-center justify-between mb-3">
                            <div className="key direction-row flex items-center">
                                <p className="text text-white">Total Staked</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Total " + state.totalStake + " staked in the pool"}
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value text-dark">{ state.totalStake } VELAR</p>
                        </div>
                        <div className="flex items-center justify-between mb-3">
                            <div className="key direction-row flex items-center">
                                <p className="text text-white">Pool Share</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Your " + (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) + "% share in the staking pool"} 
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value text-dark">{ (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) }%</p>
                        </div>
                        {/* <div className="flex items-center justify-between mb-3">
                            <p className="key text-white">Contract address</p> 
                            <p className="value text-dark">0x124cw....231de2</p>
                        </div> */}
                        {/* <hr className="my-3" />
                        <div className="flex items-center justify-between">
                            <p className="key text-warning">You wil get</p> 
                            <p className="value text-warning">~$3.62 VELAR</p>
                        </div> */}
                        </div>
                    </div>

                    {!auth.isLoggedIn ? (
                        <button onClick={() => {
                            setLayoutState({
                                showSideBar: !layoutState.showSideBar,
                                showWalletSidebar: !layoutState.showWalletSidebar
                            })
                        }} className="btn-warning">Connect wallet</button>
                    ) : (
                        <>
                            { state.deamount > state.debalance ? (
                                <button className="btn-insufficient">Insufficient Balance</button>
                            ) : (
                                <button onClick={(e) => aproveUnStack()} disabled={(state.deamount < 1)} className={((state.deamount < 1) ? 'disabled ' : 'hand ') + "btn-warning" }>Remove Staking</button>
                            ) }
                        </>
                    )}

                    {/* <button className="btn-dark">Connect wallet</button> */}
                    {/* <button className="btn-insufficient">Insufficient Balance</button> */}
                    {/* <button className="btn-warning">Approve unstaking</button> */}
                </div>
            )}

            {state.tab === Tabs.MyRewards && (
                <MyRewards currentEpoch={epoch.epoch} />
            )}

            {(state.successfulModal) && (
                <SuccessfulModal
                    forType={'stake'}
                    message={state.txMessage}
                    state={state}
                    onClose={() => {
                        setState({ ...state, successfulModal: false, confirmModal: false });
                        toggleBodyScroll(false)
                    }
                } />
            )}
        </div>

    )
}
