import React, { useContext, useEffect, useRef } from 'react';
import { WalletToken } from './WalletToken';
import { WalletActivity } from './WalletActivity';
import { WalletPool } from './WalletPool';
import ActionSheet from "actionsheet-react";
import { LayoutContext } from '../../contexts/LayoutContext';
import { WalletState } from './Wallet';
import { WalletTabs } from './WalletTabs';
import { copyToClipboard } from '../../helper';
import { AppConstants } from '../../constants';
import { isStacksWalletInstalled, isMobile } from '@stacks/connect';
import ExchangeService from '../../services/ExchangeService';



export const WalletMobile = (props) => {
    const { state, setState, logout, onConnect, balance } = props;
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const actionSheetRef = useRef();

    useEffect(() => {
        if (layoutState.showWalletSidebar) {
            actionSheetRef.current.open();
        } else {
            actionSheetRef.current.close();
        }
    }, [layoutState.showWalletSidebar])


    const hiroWalletConnect = ()=>{
        if(state.walletInstall.hiro){
            onConnect(AppConstants.Wallets.Hiro)
           } else {
            window.open(AppConstants.Hiro.browserExtensionLink, '_blank');
           }
    }
    const xverseWalletConnect = () => {
        if (!isStacksWalletInstalled() && isMobile()) {
            setTimeout(() => {
                window.location.href = "https://www.xverse.app/"
            }, 30)
            window.location.href = `xverse://browser?url=${encodeURIComponent(
                window.location.href,
            )}`
        } else {
            onConnect(AppConstants.Wallets.XVerse)
        }
    }

    return (
        <ActionSheet
            onClose={() => {
                setLayoutState({ showSideBar: false, showWalletSidebar: false })
            }}
            ref={actionSheetRef}
            sheetStyle={{
                backgroundColor: '#08090A',
                paddingBottom: 20,
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
            }}
            bgStyle={{
                backgroundColor: "rgba(1, 1, 1, 0.8)"
            }}>
            <div className='wallet-info actionsheet p-5'>
                <div className='actionsheet-bar'></div>
                <div className='flex items-center justify-between mb-8 gap-x-5'>
                    <div className="profile">
                        {state.isLoggedIn ?
                            <>
                                <div className="icon">
                                    <img src='/assets/icons/icon-profile.svg' alt="" />
                                </div>
                                <div>
                                    <h6 className='name'>{state.authData.stxAddress.address.substr(0, 5)}</h6>
                                    <div className='flex items-center'>
                                        <p className='text'>{state.authData.stxAddress?.address?.substr(0, 5)}...{state?.authData?.stxAddress?.address?.substr(state.authData?.stxAddress?.address?.length - 5)}</p>
                                        <button onClick={() => copyToClipboard(state?.authData?.stxAddress?.address, 'top-center')} className='btn-copy-text'>
                                            <img className='ml-1' src='/assets/icons/icon-copy-text.svg' alt="" />
                                        </button>
                                    </div>
                                </div>
                            </> : ''}
                    </div>
                    {state.isLoggedIn ?
                        <div className="action-btns">
                            <button onClick={() => {
                                logout();
                                setState({ ...state, isLoggedIn: !state.isLoggedIn })
                            }}>
                                <img src='/assets/icons/icon-power.svg' alt="" />
                            </button>
                            <button>
                                <img src='/assets/icons/icon-cube.svg' alt="" />
                            </button>
                        </div> : ''}
                </div>
                {state.isLoggedIn ?
                    <div className="balance mb-10">
                        <h6 className='heading'>Wallet Balance</h6>
                        <div className='flex items-end'>
                            <p className='coin-name'>{String(state.balance)} STX</p>
                            <p className='converted-into'>(${ExchangeService.getTokenDollarPrice(state.balance, 'wstx', true, 3)})</p>
                        </div>
                    </div> : ''}
                {state.isLoggedIn &&
                    <>
                        <WalletTabs {...props} />
                        {state.tab === WalletState.Token && <WalletToken state={state}></WalletToken>}
                        {state.tab === WalletState.Activity && <WalletActivity state={state} transactions={state.transactions}></WalletActivity>}
                        {state.tab === WalletState.Pool && <WalletPool state={state}></WalletPool>}
                    </>
                }
            </div>
            {!state.isLoggedIn && (
                <div className="connect-wallet">
                    <div className='flex items-center justify-between mb-9 gap-x-5'>
                        <h5 className='heading'>Connect a wallet</h5>
                        <div className="action-btns">
                            {/* <button onClick={() => setState({ ...state, isLoggedIn: !state.isLoggedIn })}>
                                <img src='/assets/icons/icon-power.svg' alt="" />
                            </button>
                            <button>
                                <img src='/assets/icons/icon-cube.svg' alt="" />
                            </button> */}
                        </div>
                    </div>
                    <div className='wallets-list'>
                        <div className='item'>
                            <div onClick={() => xverseWalletConnect()} className='flex items-center cursor-pointer'>
                                <div className='icon flex items-center justify-start'>
                                    <img style={{ width: 24 }} src='/assets/icons/icon-xverse.svg' alt="" />
                                </div>
                                <div className='flex items-center justify-start gap-x-3'>
                                    <p className='name'>Xverse</p>
                                    {/* {!state.walletInstall.xverse ? <p className='not-installed'>Not installed</p> : ''} */}
                                </div>
                            </div>
                            {/* {!state.walletInstall.xverse ? <button className='cursor-pointer btn-wallet-link'>
                                <img src='/assets/icons/icon-wallet-link.svg' alt="" />
                            </button> : ''} */}
                        </div>
                        {/* <div className='item'>
                            <div onClick={() => hiroWalletConnect()} className='flex items-center cursor-pointer'>
                                <div className='icon'>
                                    <img src='/assets/icons/icon-hiro-wallet.svg' alt="" />
                                </div>
                                <div className='flex items-center justify-start gap-x-3'>
                                    <p className='name'>Hiro Wallet </p>
                                    {!state.walletInstall.hiro ? <p className='not-installed'>Not installed</p> : ''}
                                </div>
                            </div>
                            {!state.walletInstall.hiro ? <button className='cursor-pointer btn-wallet-link'>
                                <img src='/assets/icons/icon-wallet-link.svg' alt="" />
                            </button> : ''}
                        </div> */}
                    </div>
                    <p className='t-and-c-text'>
                        By connecting a wallet, you agree to Velar’s <a href='#'>Terms and conditions and Privacy policy</a>
                    </p>
                </div>
            )}
        </ActionSheet>
    )
}