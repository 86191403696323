import { Link, useParams } from "react-router-dom"
import AddLiquidity from "../Liquidity/AddLiquidity"
import { MyLiquidity } from "../Liquidity/MyLiquidity"
import { useEffect, useRef, useState } from "react";
import StakeView from "./StakeView";
import MyInvestmentView from "./MyInvestmentView";
import FarmProcess from "../FarmProcess";
import Breadcrumbs from "../Breadcrumbs";
import PoolService from "../../services/PoolService";
import CoreContractService from "../../services/CoreContractService";
import { useDispatch, useSelector } from "react-redux";
import PoolContractService from "../../services/PoolContractService";
import { getAccountTokens, setTokenLoader } from "../../store/slices/authSlice";
import Loader from "../Loader";

export const FarmStake = (props) => {
    const [ settings, setSettings ] = useState({ pool: {}, poolInfo: { name: '' } })
    const params = useParams();
    const swap = useSelector((x) => x.swap);
    const auth = useSelector((x) => x.auth);
    const dispatch = useDispatch();
    
    const settingRef = useRef(settings);
    const authRef = useRef(auth);

    const [state, setState] = useState({
        isStakeView: false,
        isMyInvestmentView: true,
    });

    useEffect(() => {
        init ();
    }, [])
    
    const init = async () => {
        dispatch(setTokenLoader(true));
        dispatch(getAccountTokens());
        const poolInfo = await PoolService.getPoolBySymbol(params.id);
        const poolContractData = await CoreContractService.getPool();
        const poolData = poolContractData?.value?.data || {};
        settings.pool = { ...poolData };
        settings.poolInfo = poolInfo.message;
        setSettings({ ...settings });
    }

    if (!settings.poolInfo.id) {
        return (
            <div className="farm-details mt-15">
                <Loader />
            </div>
        )
    }

    return (
        <div className="farm-details">
            <Breadcrumbs crumbs={[{ name: 'Farm', link: '/farm' }, { name: settings.poolInfo.lpTokenSymbol, link: '/farm-stake' }]} />
            {/* <Link to="/farm" className="go-back-link">
                <img className='w-4 mr-2' src="/assets/icons/back-arrow.svg" alt="back-arrow" />
                Go back
            </Link> */}
            {settings.poolInfo.id && (
                <div className="inline-flex items-center mb-9 md:mb-6">
                    <div className="icons">
                        <img
                            className="z-6"
                            src={settings.poolInfo.token0ImageUrl}
                            alt="coin two" />
                        <img
                            style={{ borderRadius: '100%', border: '1px solid #222' }}
                            className="z-7"
                            src={settings.poolInfo.token1ImageUrl}
                            alt="coin one" />
                    </div>
                    <div className="relative">
                        <div className="coins">
                            { settings.poolInfo?.name }
                            <Link to="#" className="ml-1">
                                <img className='w-5' src="/assets/icons/link.svg" alt="link" />
                            </Link>
                        </div>
                        <p className="fee">
                            Fee: <span className="">0.3%</span>
                        </p>
                    </div>
                </div>
            )}
            {settings.poolInfo.id ? (
                <FarmProcess  settings={settings} />
            ) : (
                <div className="mt-10">
                    <Loader />
                </div>
            )}
            {/* <div className="bg-dark-700 border border-white/20 rounded-md py-4 px-4">
                <div className="py-2 space-x-4">
                    <button
                        onClick={() => setState({ isStakeView: true, isMyInvestmentView: false })}
                        className={"hover:text-white text-2xl font-medium " + (state.isStakeView ? "text-white" : "text-gray")}>
                        Stake
                    </button>
                    <button
                        onClick={() => setState({ isMyInvestmentView: true, isStakeView: false })}
                        className={"hover:text-white text-2xl font-medium " + (state.isMyInvestmentView ? "text-white" : "text-gray")}>
                        My Investment
                    </button>
                </div>

                { state.isStakeView && <StakeView /> }
                { state.isMyInvestmentView && <MyInvestmentView /> }

            </div> */}
        </div>
    )
}

