import React from 'react';
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMoreTransactions, getTransactions, setNotificationParams, setTransactionLoader } from "../store/slices/chainSlice";
import { LayoutContext } from "../contexts/LayoutContext";
import Loader from "../components/Loader";
import { getContractAddressAndName, getFirstAndLast, getTokenBasedDecimalValue, getTokenBasedExcatValue } from "../helper";
import { isBaseAddress, renderLiquidityPollTransaction, renderNotifications, renderSwapTransaction } from "../utils/notification.helper";
import { getTokens } from "../store/slices/swapSlice";
import { AppConstants } from "../constants";
import { Config } from "../config";

const Tabs = {
    Updates: 'pending',
    Swap: 'swap',
    Stake: 'stake',
    Rewards: 'rewards',
    Liquidity: 'pool',
    Farm: 'farm',
    All: '',
}

const Images = {
    ['pending']: '/assets/icons/updates.svg',
    swap: '/assets/icons/swap.svg',
    stake: '/assets/icons/swap.svg',
    pool: '/assets/icons/rewards.svg',
    Rewards: '/assets/icons/rewards.svg',
    farm: '/assets/icons/rewards.svg',
    '': '/assets/icons/double-check.svg',
}

export const Notifications = (props) => {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const [tab, setTab] = useState(Tabs.All)
    const [state, setState] = useState({ md: false })
    const [activeOver, setActiveOver] = useState('')
    const ref = useRef(null);
    const chain = useSelector((x) => x.chain);
    const swap = useSelector((x) => x.swap);

    const dispatch = useDispatch();
    const [currentTransactions, setCurrentTransactions] = useState([]);
    const [memePool, setMemePool] = useState([]);
    const transactions = chain.transaction.data.result;
    const transactionLoader = chain.transaction.loader;
    const mempoolTransaction = transactions?.filter((x) => x.memepool && isBaseAddress(x?.contract_call?.contract_id));
    const txMeta = chain?.transaction?.data?.meta?.transactions || { offset: 0, total: 0 };

    // useEffect(() => {
    //     dispatch(setNotificationParams({ type: tab }));
    //     dispatch(setTransactionLoader(true));
    //     dispatch(getTransactions());
    // }, [tab])

    useEffect(() => {        
        let txs = (transactions || [])?.filter((x) => {
            if (tab === Tabs.Swap) {
                if (x?.contract_call?.function_name?.indexOf('swap') > -1 && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            } else if (tab === Tabs.Stake) {
                if (Config.ContractAddresses.Staking === x?.contract_call?.contract_id && x?.contract_call?.function_name?.indexOf('stake') > -1 && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            }  else if (tab === Tabs.Farm) {
                if (x?.contract_call?.contract_id?.indexOf('farming-core') && x?.contract_call?.function_name?.indexOf('stake') > -1 && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            } else if ( tab === Tabs.Rewards )  {
                if (AppConstants.STAKE_REWARDS.includes(x?.contract_call?.function_name) && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            } else if ( tab === Tabs.Updates )  {
                if (x?.memepool) {
                    return x;
                }
            } else if (tab === Tabs.Liquidity) {
                if (AppConstants.POOL_FUNCTIONS.includes(x?.contract_call?.function_name) && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            } else {
                return x;
            }
        }).filter(Boolean);
        setMemePool(mempoolTransaction || []);
        setCurrentTransactions(txs);

    }, [tab, (transactions || []).length, mempoolTransaction?.length])

    useEffect(() => {
        setLayoutState({ ...layoutState, isNotificationViewed: true });
        dispatch(setTransactionLoader(true));
        dispatch(getTransactions());
        dispatch(getTokens());

        return (() => {
            dispatch(setNotificationParams({ type: 'all' }));
            dispatch(setTransactionLoader(true));
            dispatch(getTransactions());
            dispatch(getTokens());
        })
    }, [])

    const loadMore = () => {
        dispatch(setTransactionLoader(true));
        dispatch(getMoreTransactions(txMeta.offset + 50));
    }

    return (
        <section className="notifications-main max-w-950">
            <div className="global-page-header">
                <h2 className="title">Notifications</h2>
                <h6 className="desc">Provide liquidity and earn fees</h6>
            </div>
            <div className="sm-notifications-filter lg:hidden">
                <p className="heading text-gray text-lg">
                    Filter By
                </p>
                <button
                    onClick={() => {
                        setState({ ...state, md: !state.md });
                    }}
                    className="btn-select"
                    id="dropdownDividerButton" data-dropdown-toggle="dropdownDivider" type="button">
                    <img className="h-3 w-auto" src={Images[tab]} alt="double-check" />
                    <span className="inline-block text-white">
                        { Tabs.All == tab ? 'All Notifications' : tab }
                    </span>
                    <img className="h-5 w-auto" src="/assets/icons/list-arrow.svg" alt="list-arrow" />
                </button>

                <div style={{ position: 'absolute', top: 205, right: 23 }} className={(state.md ? '' : 'hidden ') + "z-10 bg-[#0D0807] divide-gray-100 rounded-lg shadow w-44"}>
                    <ul className="dd-list" aria-labelledby="dropdownDividerButton">
                        <li>
                            <Link 
                                onMouseOver={() => setActiveOver(Tabs.Updates)}
                                onMouseOut={() => setActiveOver('')}
                                onClick={() => { setTab(Tabs.Updates); setState({ ...state, md: false }) }} to="#" className="item mb-2">
                                <div className="flex items-center">
                                    <div className="flex items-center" style={{ minWidth: 20 }}>
                                        <img src={Tabs.Updates == activeOver || Tabs.Updates == tab ? "/assets/icons/updates.svg" : "/assets/icons/updates-inverse.svg"} alt="update icon" />
                                    </div>
                                    <div className="flex space-between items-center">
                                        <div className={(Tabs.Updates == tab ? 'noti-tab-active ' : '') + "text-sm text font-bold"}>Pending Txs</div>
                                        <div style={{ marginLeft: 30, background: 'rgba(248, 157, 51, 0.15)' }} className="inline-flex items-center justify-center px-1 text-sm rounded-md font-semibold text-primary bg-primaryDark">{ memePool.length }</div>
                                    </div>
                                </div>
                            </Link>
                            <Link 
                                onMouseOver={() => setActiveOver(Tabs.Swap)}
                                onMouseOut={() => setActiveOver('')}
                                onClick={() => { setTab(Tabs.Swap); setState({ ...state, md: false }) }}
                                to="#" className="item mb-2">
                                <div className="flex items-center">
                                    <div className="flex items-center" style={{ minWidth: 20 }}>
                                        <img src={Tabs.Swap == activeOver || Tabs.Swap == tab ? "/assets/icons/swap.svg" : "/assets/icons/swap-inverse.svg"} alt="update icon" />
                                    </div>
                                    <span className={(Tabs.Swap == tab ? 'noti-tab-active ' : '') + "text-sm text font-bold"}>Swap</span>
                                </div>
                            </Link>                            
                            <Link 
                                onMouseOver={() => setActiveOver(Tabs.Liquidity)}
                                onMouseOut={() => setActiveOver('')}
                                onClick={() => { setTab(Tabs.Liquidity); setState({ ...state, md: false }) }}
                                to="#" className="item mb-2">
                                <div className="flex items-center">
                                    <div className="flex items-center" style={{ minWidth: 20 }}>
                                        <img src={Tabs.Liquidity == activeOver || Tabs.Liquidity == tab ? "/assets/icons/rewards.svg" : "/assets/icons/rewards-inverse.svg"} alt="update icon" />
                                    </div>
                                    <span className={(Tabs.Liquidity == tab ? 'noti-tab-active ' : '') + "text-sm text font-bold"}>Pool</span>
                                </div>
                            </Link>
                            { Config.name === AppConstants.Networks.TESTNET && (
                                <React.Fragment>
                                    <Link 
                                        onMouseOver={() => setActiveOver(Tabs.Stake)}
                                        onMouseOut={() => setActiveOver('')}
                                        onClick={() => { setTab(Tabs.Stake); setState({ ...state, md: false }) }}
                                        to="#" className="item mb-2">
                                        <div className="flex items-center">
                                            <div className="flex items-center" style={{ minWidth: 20 }}>
                                                <img src={Tabs.Stake == activeOver || Tabs.Stake == tab ? "/assets/icons/swap.svg" : "/assets/icons/swap-inverse.svg"} alt="update icon" />
                                            </div>
                                            <span className={(Tabs.Stake == tab ? 'noti-tab-active ' : '') + "text-sm text font-bold"}>Stake</span>
                                        </div>
                                    </Link>
                                    <Link 
                                        onMouseOver={() => setActiveOver(Tabs.Rewards)}
                                        onMouseOut={() => setActiveOver('')}
                                        onClick={() => { setTab(Tabs.Rewards); setState({ ...state, md: false }) }}
                                        to="#" className="item mb-2">
                                        <div className="flex items-center">
                                            <div className="flex items-center" style={{ minWidth: 20 }}>
                                                <img src={Tabs.Rewards == activeOver || Tabs.Rewards == tab ? "/assets/icons/swap.svg" : "/assets/icons/swap-inverse.svg"} alt="update icon" />
                                            </div>
                                            <span className={(Tabs.Rewards == tab ? 'noti-tab-active ' : '') + "text-sm text font-bold"}>Rewards</span>
                                        </div>
                                    </Link>
                                    <Link 
                                        onMouseOver={() => setActiveOver(Tabs.Farm)}
                                        onMouseOut={() => setActiveOver('')}
                                        onClick={() => { setTab(Tabs.Farm); setState({ ...state, md: false }) }}
                                        to="#" className="item mb-2">
                                        <div className="flex items-center">
                                            <div className="flex items-center" style={{ minWidth: 20 }}>
                                                <img src={Tabs.Farm == activeOver || Tabs.Farm == tab ? "/assets/icons/swap.svg" : "/assets/icons/swap-inverse.svg"} alt="update icon" />
                                            </div>
                                            <span className={(Tabs.Farm == tab ? 'noti-tab-active ' : '') + "text-sm text font-bold"}>Farm</span>
                                        </div>
                                    </Link>
                                </React.Fragment>
                            ) }
                            
                          
                         
                            <Link 
                                onMouseOver={() => setActiveOver(Tabs.All)}
                                onMouseOut={() => setActiveOver('')}
                                onClick={() => { setTab(Tabs.All); setState({ ...state, md: false }) }}
                                // onClick={() => setTab(Tabs.All)} 
                                to="#" className="item mb-2">
                                <div className="flex items-center">
                                    <div className="flex items-center" style={{ minWidth: 20 }}>
                                        <img src={Tabs.All == activeOver || Tabs.All == tab ? "/assets/icons/all-notifications.svg" : "/assets/icons/all-notifications-inverse.svg"} alt="update icon" />
                                    </div>
                                    <span className={(Tabs.All == tab ? 'noti-tab-active ' : '') + "text-sm text font-bold"}>All</span>
                                </div>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="#" className="item">
                                <div className="flex items-center">
                                    <img src="/assets/icons/swap.svg" alt="swap icon" />
                                    <span className="text">Swap</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="item">
                                <div className="flex items-center">
                                    <img src="/assets/icons/swap.svg" alt="swap icon" />
                                    <span className="text">Swap</span>
                                </div>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-4 gap-y-4 mt-6 md:mt-0">
                <div className="hidden lg:block col-span-3 lg:col-span-1 border-r border-white/20">
                    <Link 
                        onMouseOver={() => setActiveOver(Tabs.Updates)}
                        onMouseOut={() => setActiveOver('')}
                        onClick={() => setTab(Tabs.Updates)} to="#" className="flex justify-between items-center px-4 py-3 text-gray hover:text-white">
                        <div className="flex space-x-2">
                            <div className="flex items-center" style={{ minWidth: 20 }}>
                                <img src={Tabs.Updates == activeOver || Tabs.Updates == tab ? "/assets/icons/updates.svg" : "/assets/icons/updates-inverse.svg"} alt="update icon" />
                            </div>
                            <span className={(Tabs.Updates == tab ? 'noti-tab-active ' : '') + "text-sm font-bold"}>Pending Txs</span>
                        </div>
                        <span style={{ marginRight: 55, background: 'rgba(248, 157, 51, 0.15)' }} className="inline-flex items-center justify-center px-1 text-sm rounded-md font-semibold text-primary bg-primaryDark">{ memePool?.length }</span>
                    </Link>
                    <Link 
                        onMouseOver={() => setActiveOver(Tabs.Swap)}
                        onMouseOut={() => setActiveOver('')}
                        onClick={() => setTab(Tabs.Swap)} to="#" className="flex justify-between items-center px-4 py-3 text-gray hover:text-white">
                        <div className="flex space-x-2">
                            <div className="flex items-center" style={{ minWidth: 20 }}>
                                <img src={Tabs.Swap == activeOver || Tabs.Swap == tab ? "/assets/icons/swap.svg" : "/assets/icons/swap-inverse.svg"} alt="swap icon" />
                            </div>
                            <span className={(Tabs.Swap == tab ? 'noti-tab-active ' : '') + "text-sm font-bold" }>Swap</span>
                        </div>
                    </Link>
                    <Link 
                        onMouseOver={() => setActiveOver(Tabs.Liquidity)}
                        onMouseOut={() => setActiveOver('')}
                        onClick={() => setTab(Tabs.Liquidity)} to="#" className="flex justify-between items-center px-4 py-3 text-gray hover:text-white">
                        <div className="flex space-x-2">
                            <div className="flex items-center" style={{ minWidth: 20 }}>
                                <img src={Tabs.Liquidity == activeOver || Tabs.Liquidity == tab ? "/assets/icons/rewards.svg" : "/assets/icons/rewards-inverse.svg"} alt="swap icon" />
                            </div>
                            <span className={(Tabs.Liquidity == tab ? 'noti-tab-active ' : '') + "text-sm font-bold" }>Pool</span>
                        </div>
                    </Link>
                    { Config.name === AppConstants.Networks.TESTNET && (
                        <React.Fragment>

                            <Link 
                                onMouseOver={() => setActiveOver(Tabs.Stake)}
                                onMouseOut={() => setActiveOver('')}
                                onClick={() => setTab(Tabs.Stake)} to="#" className="flex justify-between items-center px-4 py-3 text-gray hover:text-white">
                                <div className="flex space-x-2">
                                    <div className="flex items-center" style={{ minWidth: 20 }}>
                                        <img src={Tabs.Stake == activeOver || Tabs.Stake == tab ? "/assets/icons/swap.svg" : "/assets/icons/swap-inverse.svg"} alt="swap icon" />
                                    </div>
                                    <span className={(Tabs.Stake == tab ? 'noti-tab-active ' : '') + "text-sm font-bold" }>Stake</span>
                                </div>
                            </Link>
                            <Link 
                                onMouseOver={() => setActiveOver(Tabs.Rewards)}
                                onMouseOut={() => setActiveOver('')}
                                onClick={() => setTab(Tabs.Rewards)} to="#" className="flex justify-between items-center px-4 py-3 text-gray hover:text-white">
                                <div className="flex space-x-2">
                                    <div className="flex items-center" style={{ minWidth: 20 }}>
                                        <img src={Tabs.Rewards == activeOver || Tabs.Rewards == tab ? "/assets/icons/swap.svg" : "/assets/icons/swap-inverse.svg"} alt="swap icon" />
                                    </div>
                                    <span className={(Tabs.Rewards == tab ? 'noti-tab-active ' : '') + "text-sm font-bold" }>Rewards</span>
                                </div>
                            </Link>
                            <Link 
                                onMouseOver={() => setActiveOver(Tabs.Farm)}
                                onMouseOut={() => setActiveOver('')}
                                onClick={() => setTab(Tabs.Farm)} to="#" className="flex justify-between items-center px-4 py-3 text-gray hover:text-white">
                                <div className="flex space-x-2">
                                    <div className="flex items-center" style={{ minWidth: 20 }}>
                                        <img src={Tabs.Farm == activeOver || Tabs.Farm == tab ? "/assets/icons/swap.svg" : "/assets/icons/swap-inverse.svg"} alt="swap icon" />
                                    </div>
                                    <span className={(Tabs.Farm == tab ? 'noti-tab-active ' : '') + "text-sm font-bold" }>Farm</span>
                                </div>
                            </Link>
                        </React.Fragment>
                    )}
                    <Link 
                        onMouseOver={() => setActiveOver(Tabs.All)}
                        onMouseOut={() => setActiveOver('')}
                        onClick={() => setTab(Tabs.All)} to="#" className="flex justify-between items-center px-4 py-3 text-gray hover:text-white">
                        <div className="flex space-x-2">
                            <div className="flex items-center" style={{ minWidth: 20 }}>
                                <img src={Tabs.All == activeOver || Tabs.All == tab ? "/assets/icons/all-notifications.svg" : "/assets/icons/all-notifications-inverse.svg"} alt="swap icon" />
                            </div>
                            <span className={(Tabs.All == tab ? 'noti-tab-active ' : '') +  "text-sm font-bold" }>All Notifications</span>
                        </div>
                    </Link>
                </div>

                <div className="col-span-3 lg:col-span-3 lg:ml-8">
                    <div className="all-notifications">
                        {(transactionLoader) && (
                            <div className="items-center justify-center text-center mt-5">
                                <Loader width={32} />
                            </div>
                        )}
                        {(currentTransactions || []).length === 0 && (
                            <div className="flex items-center justify-center" style={{ color: '#444' }}>
                                { !tab ? (
                                    `no transactions found.`
                                ): (
                                    `no ${ tab } transactions found.`
                                ) }
                            </div>
                        )}
                        {(currentTransactions || []).map((transaction, index) => {
                           return renderNotifications(transaction, index, ref, 'page', swap.tokens?.message || []);
                        })}
                        {(txMeta.total !== 0 && (Number(txMeta?.offset) >= Number(txMeta?.total))) ? (
                            <div className="flex items-center justify-center mt-5 mb-10">
                                <button onClick={() => loadMore()} style={{ width: 200, padding: 5, height: 45, backgroundColor: 'rgb(27 26 25)', color: '#999', fontWeight: '400' }} className="btn-insufficient p-3">
                                    { transactionLoader ? (
                                        <Loader />
                                    ) : (
                                        'Load More'
                                    ) }
                                </button>
                            </div>
                        ) : '' }
                    </div>
                </div>
            </div>
        </section>
    )
}