export const AppConstants = {
    NATIVE_TOKEN_NAME: {
        'wstx': 'stx'
    },
    App: {
        URL: 'https://dev--iridescent-melba-80337e.netlify.app/',
        Name: 'Velar',
        Icon: window.location.origin + '/assets/imgs/velar-logo.png'
    },
    Wallets: {
        Hiro: 'hiro',
        XVerse: 'xverse',
        Asigna: 'asigna'
    },
    Hiro :{
        ID: 'leather-provider',
        browserExtensionLink:'https://chrome.google.com/webstore/detail/hiro-wallet/ldinpeekobnhjjdofggfgjlcehhmanlj'
    },
    XVerse :{
        ID: 'xverse-wallet-provider',
        browserExtensionLink:'https://chrome.google.com/webstore/detail/xverse-wallet/idnnbdplmphpflfnlkomgpfbpcgelopg'
    },
    Asigna :{
        ID: 'asigna-wallet-provider',
        browserExtensionLink:'https://chromewebstore.google.com/detail/asigna-multisig/gopddoinbenlokghakiafackadkbnlid?hl=en'
    },
    Networks: {
        TESTNET: 'testnet',
        MAINNET: 'mainnet',
        DEVNET: 'devnet'
    },
    PostConditionType: {
        ContractFungible: 'contract-fungible',
        StandardSTX: 'standard',
        StandardSTXFungible: 'standard-fungible',
        ContractSTX: 'contract-stx',
    },
    NATIVE_TOKEN_SYMBOLS: ['wstx', 'stx'],
    NATIVE_TOKEN_SYMBOL: 'wstx',
    NATIVE_TOKEN_SYMBOLS: ['wstx', 'stx'],
    WalletConnect: {
        CHAINS: [
            'stacks:1',
            'stacks:2147483648',
        ]
    },
    SWAP_FUNCTIONS: [
        'swap-tokens-for-exact-tokens',
        'swap-exact-tokens-for-tokens'
    ],
    STAKE_FUNCTIONS: [
        'stake',
        'unstake'
    ],
    FARM_STAKE_FUNCTIONS: [
        'stake',
        'unstake'
    ],
    STAKE_REWARDS: [
        'distribute-epochs'
    ],
    POOL_FUNCTIONS: [
        'add-liquidity',
        'remove-liquidity'
    ],
    Views: {
        GRID: 'GRID',
        LIST: 'LIST'
    },
    LOCAL_STORAGE_KEYS: {
        App: 'app'
    },
    AUTH_MESSAGE: (nonce) => `Liquidity Protocol on Bitcoin - Swap, Stake, Farm, IDO Launchpad \n\nnonce: ${nonce}`
}
