import { uniqueId } from "lodash";
import { useState, useEffect } from "react";
import DistributorContractService from '../services/DistributorContractService';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import BlockService from "../services/BlockService";
import axios from "axios";
import StakeContractService from "../services/StakeContractService";
import { stringify, toggleBodyScroll, velarToast } from "../helper";
import { toast } from "react-toastify";
import { Config } from "../config";
import { SuccessfulModal } from "../modals/SuccessfulModal";

const BlockInfo = (props) => {
  const { block, index } = props;
  const [state, setState] = useState({ loader: false, successfulModal: false,  details: false, id: uniqueId() })
  const firstEpoch = block.epochs[0];
  const lastEpoch = block.epochs.at(-1);
  const epouchs = block.epochs.length > 1 ? "epochs " + firstEpoch + ".." + lastEpoch : "epoch " + firstEpoch;

  const claimRewards = async () => {
    setState({ ...state, loader: true });
    const result = await DistributorContractService.distributeBlocks(block.epochs);
    setState({ ...state, successfulModal: true });
    velarToast(<div>
        <div className="title">Rewards Claimed from { epouchs }</div>
        <div className="timestamp">Less than 25 sec ago</div>
        <a className="link" target="_blank" href={`${Config.url}txid/${result.txId}?chain=${Config.name}`}>
            View on explorer
            <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
        </a>
    </div>);
  }

  return (
    <div key={index} className="block-info mb-5">
      <div className="block-details" data-accordion="collapse">
        <button
          onClick={() =>
            setState({ ...state, details: !state.details })
          }
          type="button"
          className="block-btn text-pink-100 font-bold text-lg flex items-center justify-between w-full"
          data-accordion-target={"#" + state.id}
          ariaaria-expanded="true"
          aria-controls="collapsible-details"
        >
          <span className="title">{ block.start }</span>
          <div className="dotted-line"></div>
          <span className="title">Epoch { block.end }</span>
          <svg
            data-accordion-icon
            className={
              "w-6 h-6 shrink-0 " + (!state.details && "rotate-180")
            }
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div id={state.id} className={state.details ? "details hidden" : "details"}>
          <div className="flex items-center justify-between mb-3">
            <div className="key direction-row flex items-center">
              <p className="text text-white">Rewards Share</p>
              <img
                data-tooltip-id="tooltip"
                data-tooltip-content={"Rewards Share"}
                className="icon hand w-[15px] ml-2"
                src="/assets/icons/icon-help-white.svg"
                alt=""
              />
            </div>
            <p className="value text-white">3%</p>
          </div>
          <div className="flex items-center justify-between mb-3">
            <div className="key direction-row flex items-center">
              <p className="text text-white">IDO Tickets</p>
              <img
                data-tooltip-id="tooltip"
                data-tooltip-content={"Rewards Share"}
                className="icon hand w-[15px] ml-2"
                src="/assets/icons/icon-help-white.svg"
                alt=""
              />
            </div>
            <p className="value text-white">04</p>
          </div>
          { block.hasRewards ? (
            <button onClick={claimRewards} className="btn btn-rewards">Claim Rewards</button>
          ) : (
            <button className="cursor-default btn btn-rewards">No Rewards found</button>
          ) }
        </div>
      </div>
      { state.successfulModal && (
        <SuccessfulModal
            forType={'rewards'}
            state={state}
            message={`Rewards Claimed from ${epouchs}`}
            onClose={() => {
                setState({ ...state, successfulModal: false });
                toggleBodyScroll(false);
                props.get && props.get();
            }
        } />
      )}
    </div>
  )
}

export const MyRewards = (props) => {
  const { currentEpoch } = props;
  const dispatch = useDispatch();
  const [ loader, setLoader ] = useState(false);
  const [ blocks, setBlocks ] = useState([]);
  const auth = useSelector((x) => x.auth);

  useEffect(() => {
    if (Number(currentEpoch) > -1) {
      init ();
    }
  }, [currentEpoch])

  const init = async () => {
    try {
      setLoader(true);
      const epochsRequests = [];
      for (let i = 0; i < currentEpoch; i++) {
        epochsRequests.push(DistributorContractService.getReward(i));
      }
      const rewards = await axios.all(epochsRequests);
      const _blocks = [];
      let startEpoch = 0;
      rewards.chunk(2).forEach((reward, index) => {        
        const hasRewards = reward.find((x) => Number(x.value.data.token0.value) > 0 || Number(x.value.data.token0.value) > 0);
        _blocks.push(
          { epochs: [startEpoch, startEpoch + 1], start: `Epoch ${startEpoch}`, end: startEpoch + 1, hasRewards }
        )
        startEpoch += 2;
      })
      setBlocks([ ..._blocks ])
      setLoader(false);      
    } catch (error) {
      console.log(error);
      setLoader(false);      
    }
  }

  return (
    <div className="my-rewards">
      { loader ? (
        <div className="p-5 m-5">
          <Loader />
        </div>
      ) : (
        <div className="block-list">
          {blocks.map((block, index) => {
            return <BlockInfo get={init} key={index} {...props} block={block} index={index} />
          })}
        </div>
      )}
      {/* <button className="btn btn-warning">Claim all rewards</button> */}
    </div>
  );
};
