import { Link, useRoutes } from 'react-router-dom'

export default function Breadcrumbs(props) {
  const crumbs = props.crumbs || [];
  return (      
      <nav className="flex my-4" aria-label="Breadcrumb">
        <ol className="md:inline-flex items-center space-x-1 md:space-x-3">
          <li className="float-left inline-flex items-center">
            <Link to="/" className="inline-flex items-center text-base font-medium text-gray hover:text-white">
              <img className='h-3 mr-2' src="/assets/icons/home.svg" alt="home" />
              Home
            </Link>
          </li>
          {crumbs.length > 0 && (
            crumbs.map((crumb, index) => {
            return <li className='float-left mr-2' key={index}>
                <div className="flex items-center">
                    <img className='h-3' src="/assets/icons/next.svg" alt="next" />
                    <Link to={crumb.link} className="ml-1 text-base font-medium text-gray hover:text-white md:ml-2">
                      { crumb.name }
                    </Link>
                </div>
              </li>
            })
          )}
        </ol>
        {/* <ol className="md:hidden inline-flex items-center space-x-1 md:space-x-3">
        <li aria-current="page">
            <Link to="#" className="flex items-center space-x-3">
                <img className='h-4' src="/assets/icons/back-arrow.svg" alt="back-arrow" />
                <span className="ml-1 text-lg font-medium text-white">
                  Go back
                </span>
            </Link>
          </li>
        </ol> */}

      </nav>

    )
}
