import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from "react-router-dom";
import AddLiquidity from "./Liquidity/AddLiquidity";
import { MyLiquidity } from "./Liquidity/MyLiquidity";
import LiquiditySlider from './Liquidity/LiquiditySlider';
import ClaimReward from './ClaimReward';
import debounce from 'lodash/debounce';
import LibraryContractService from '../services/LibraryContractService';
import { toast } from 'react-toastify';
import RouterContractService from '../services/RouterContractService';
import { Config } from '../config';
import { useSelector } from 'react-redux';
import Loader from './Loader';
import { SuccessfulModal } from '../modals/SuccessfulModal';
import { filterDecimals, getTokenBasedDecimalValue, getTokenBasedExcatValue, getTokenDecimals, getTokenDecimalsLength, isCleanNumberInput, numberKeyUp, toggleBodyScroll, velarToast } from '../helper';
import CoreContractService from '../services/CoreContractService';
import { LayoutContext } from '../contexts/LayoutContext';
import ExchangeService from '../services/ExchangeService';
import { TokenSelectionModal } from '../modals/TokenSelectionModal';
import { TransactionSettingsModal } from '../modals/TransactionSettingsModal';
import PoolContractService from '../services/PoolContractService';
import { Input } from './Input';

const Tabs = {
    AddLiquidity: 'AddLiquidity',
    MyLiquidity: 'MyLiquidity'
}

export default function Liquidity(props) {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const auth = useSelector((x) => x.auth);
    const [state, setState] = useState({ init: false, removeLoader: false, tab: Tabs.MyLiquidity, message: '', tempVal1: '', tempVal2: '' });
    const { settings, setSettings, handleSliderComplete, totalSupply } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [poolCurrentName, setPoolCurrentName] = useState('');
    const [stamp, setStamp] = useState(null);

    useEffect(() => {
        const tab = searchParams.get("tab");
        if (tab == 'ml') {
            setState({ ...state, tab: Tabs.MyLiquidity, init: true });
        }
    }, [])

    useEffect(() => {
        if (poolCurrentName) {
            handleGetAmount(poolCurrentName);
        }
    }, [poolCurrentName, stamp]);

    const handleGetAmount = async (name) => {
        const poolContractData = await CoreContractService.getPool(settings.poolInfo.id);
        const pool = poolContractData?.value?.data || {};

        const reserve0 = pool?.reserve0?.value || 0;    
        const reserve1 = pool?.reserve1?.value || 0;
        let qoute = 0;
        try {
            const amount = getTokenBasedDecimalValue(settings[name].value, settings[name].symbol);
            if (name === 'token0') {
                if (amount !== 0) {
                    qoute = await LibraryContractService.quote(amount, reserve0, reserve1);
                }  else {
                    qoute = 0;
                }
                settings.token1.value = getTokenBasedExcatValue(qoute, settings.token1.symbol);;
            } else if (name === 'token1') {
                if (amount !== 0) {
                    qoute = await LibraryContractService.quote(amount, reserve1, reserve0);
                }  else {
                    qoute = 0;
                }
                settings.token0.value = getTokenBasedExcatValue(qoute, settings.token0.symbol);
            }

            const calcMint = await CoreContractService.calcMint(
                getTokenBasedDecimalValue(settings['token0'].value, settings['token0'].symbol),
                getTokenBasedDecimalValue(settings['token1'].value, settings['token1'].symbol),
                reserve0, 
                reserve1, 
                totalSupply
            );
            settings.lptokenOutput = calcMint;

        } catch (error) {
            console.log(error);
        }

        settings.token0.loader = false;
        settings.token1.loader = false;
        settings.lptokenOutputLoader = false;

        setSettings({ ...settings, qoute, isDirty: true });
    }

    const handleGetAmountChange = useCallback(debounce(async (name) => {
        setPoolCurrentName(name);
        setStamp(new Date().getTime());
    }, 300), [poolCurrentName]);

    const onInputChange = (e, name) => {
        if (!isCleanNumberInput(e)) {
            setSettings({ ...settings });
            return;
        }
        const value = e.target.value;
        const inverseName = name === 'token0' ? 'token1' : 'token0';

        if (!value) {
            settings[name].value = value;
            settings[inverseName].value = '';
            settings.lptokenOutput = 0;
            setSettings({ ...settings });
            return;
        }
        
        settings[name].value = value; 
        settings[inverseName].loader = true;
        settings.lptokenOutputLoader = true;

        setSettings({ ...settings });

        if (auth.isLoggedIn) {
            handleGetAmountChange(name);
        }
    }

    const onAddLiquidity = async () => {
        if (!settings.token0.value && !settings.token1.value) {
            toast('token values are not correct.', { type: 'error', position: 'bottom-right' });
            return;
        }


        const transactionPayload = {
            "publicAddress": "",
            "token0Symbol": settings.token0.symbol,
            "token0ContractAddress": settings.token0.contractAddress,
            "token1Symbol": settings.token0.symbol,
            "token1ContractAddress": settings.token1.contractAddress,
            "status": "",
            "lpTokenQuantity": settings?.lptokenOutput || 0,
            "poolId": settings.poolInfo.id,
            "lpTokenSymbol": settings.poolInfo.symbol,
        };
        transactionPayload.token0Quantity = getTokenBasedDecimalValue(settings.token0.value, settings.token0.symbol);
        transactionPayload.token1Quantity = getTokenBasedDecimalValue(settings.token1.value, settings.token1.symbol)
        transactionPayload.slippage = settings.slippage;

        let result = await RouterContractService.addLiquidity(
            settings.poolInfo.id,
            settings.token0.contractAddress,
            settings.token1.contractAddress,
            settings.poolInfo.lpTokenContractAddress,
            transactionPayload.token0Quantity,
            transactionPayload.token1Quantity,
            transactionPayload,
            settings.slippage,
            settings.poolInfo
        );

        velarToast(<div>
            <div className="title">Liquidity pool transaction added: {settings.token0.value}{settings.token0.symbol} + {settings.token1.value}{settings.token1.symbol}</div>
            <div className="timestamp">Less than 25 sec ago</div>
            <a className="link" target="_blank" href={`${Config.url}txid/${result.txId}?chain=${Config.name}`}>
                View on explorer
                <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
            </a>
        </div>);
        
        setState({ ...state, tab: Tabs.MyLiquidity, tempVal1: settings.token0.value, tempVal2: settings.token1.value, message: 'Liquidity pool transaction added: ' });
        setSettings({ ...settings, transactionUrl: `${Config.url}txid/${result.txId}?chain=${Config.name}`, successfulModal: true })
    }

    const onRemoveLiquidity = async () => {
        if (state.removeLoader) { return; }
        if (!settings.sliderValue) {
            // toast('liquidity value is not correct.', { type: 'error', position: 'bottom-right' });
            return;
        }
        const lpTokenValue = Number(auth.info.tokens.find((x) => x.contract === settings.poolInfo.lpTokenContractAddress)?.balance || 0);
        if (Number(lpTokenValue) == 0) {
            toast('you dont have sufficent balance.', { type: 'error', position: 'bottom-right' });
            return;
        }
        setState({ ...state, removeLoader: true });
        try {
            const val = Number(lpTokenValue * (settings.sliderValue / 100));
            const poolContractData = await CoreContractService.getPool(settings.poolInfo.id);
    
            const totalSupply = await PoolContractService.getTotalSupply(settings.poolInfo.lpTokenContractAddress);
            const poolData = poolContractData?.value?.data || {};
            const reserve0 = poolData['reserve0'].value;
            const reserve1 = poolData['reserve1'].value;
    
            let calcBurnResult = await CoreContractService.calcBurn(
                val,
                reserve0,
                reserve1,
                totalSupply
            );

            let amtO = getTokenBasedExcatValue((calcBurnResult?.amt0 || 0), settings.token0.symbol);
            let amt1 = getTokenBasedExcatValue((calcBurnResult?.amt1 || 0), settings.token1.symbol);
            setSettings({ ...settings, calcBurnChange: { ...settings.calcBurnChange, init: true, loader: false, amt0: amtO, amt1: amt1 } })
            
            const transactionPayload = {
                "publicAddress": "",
                "token0Symbol": settings.token0.symbol,
                "token0ContractAddress": settings.token0.contractAddress,
                "token1Symbol": settings.token0.symbol,
                "token1ContractAddress": settings.token1.contractAddress,
                "status": "",
                "lpTokenQuantity": val,
                "poolId": settings.poolInfo.id,
                "lpTokenSymbol": settings.poolInfo?.symbol,
            };
            let val1 = calcBurnResult.amt0; // getTokenBasedDecimalValue(calcBurnResult.amt0, settings.token0.symbol);
            let val2 = calcBurnResult.amt1; // getTokenBasedDecimalValue(calcBurnResult.amt1, settings.token1.symbol);
    
            val1 = Number(val1) - Number(val1) * (Number(settings.slippage) / 100);
            val2 = Number(val2) - Number(val2) * (Number(settings.slippage) / 100);

            transactionPayload.slippage = settings.slippage;

            
            let result = await RouterContractService.removeLiquidity(
                settings.poolInfo.id,
                settings.token0.contractAddress,
                settings.token1.contractAddress,
                settings.poolInfo.lpTokenContractAddress,
                val.toFixed(0),
                val1.toFixed(0),
                val2.toFixed(0),
                transactionPayload,
                settings.poolInfo
            );

            velarToast(<div>
                <div className="title">Liquidity pool transaction removed: {settings.calcBurnChange.amt0}{settings.token0.symbol} + {settings.calcBurnChange.amt1}{settings.token1.symbol}</div>
                <div className="timestamp">Less than 25 sec ago</div>
                <a className="link" target="_blank" href={`${Config.url}txid/${result.txId}?chain=${Config.name}`}>
                    View on explorer
                    <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
                </a>
            </div>);
    
            setState({ ...state, removeLoader: false, tab: Tabs.MyLiquidity, tempVal1: (settings.calcBurnChange.amt0).toFixed(6), tempVal2: (settings.calcBurnChange.amt1).toFixed(0), message: 'Liquidity pool transaction removed: ' });
            setSettings({ ...settings, transactionUrl: `${Config.url}txid/${result.txId}?chain=${Config.name}`, successfulModal: true })
        } catch (error) {
            setState({ ...state, removeLoader: false });
            // toast("Some thing went wrong.", { type: 'error', position: 'bottom-right' })
        }
    }


    const onSliderComplete = (val) => {
        settings.sliderValue = val;
        handleSliderComplete(val, settings, auth);
    }

    const isLoading = settings.token0.loader || settings.token1.loader;
    const lpTokenValue = auth?.info?.tokens?.find((x) => x.contract === settings.poolInfo.lpTokenContractAddress)?.balance || 0;
    const val = (lpTokenValue * settings.sliderValue) / 100;
    const isLowPrice = settings.isDirty && (Number(settings.token0.value) < 1 || Number(settings.token1.value) < 1)
    const isInsufficient = (Number(settings.token0.value) > Number(settings.token0.balance)) || (Number(settings.token1.value) > Number(settings.token1.balance));
    const noValue = !settings.token0.value || !settings.token0.value;


    return (
        <div className="col-span-2 md:col-span-1">
            { settings.p }
            <div className="liquidity-card">
                <div className="tabs items-center space-between">
                    <div className='flex direction-row space-between'>
                        <button
                            onClick={() => setState({ ...state, tab: Tabs.MyLiquidity })}
                            className={(state.tab === Tabs.AddLiquidity ? 'active ' : '') + "tab-item"}>
                            Add Liquidity
                        </button>
                        <div className="col-span-1 w-[20px]"></div>
                        <button
                            onClick={() => setState({ ...state, tab: Tabs.MyLiquidity })}
                            className={(state.tab === Tabs.MyLiquidity ? 'active ' : '') + "tab-item"}>
                            Remove Liquidity
                        </button>
                    </div>
                    <button
                        onClick={(() => {
                            if (auth.isLoggedIn) {
                                setSettings({ ...settings, transactionModal: true }); toggleBodyScroll(true)
                            } else {
                                setLayoutState({
                                    showSideBar: !layoutState.showSideBar,
                                    showWalletSidebar: !layoutState.showWalletSidebar
                                })
                            }
                        })}
                        className="btn-settings" type="button">
                        <img src="/assets/icons/icon-settings.svg" alt="" />
                    </button>
                </div>
                {state.tab === Tabs.AddLiquidity && (
                    <div className="add-liquidity">
                        <div className="swap-container relative">
                            <div className="swapable mb-2.5 md:mb-4">
                                <div className="flex items-center justify-between mb-1.5">
                                    <Input 
                                        onChange={(e) => onInputChange(e, 'token0')} 
                                        type="number" 
                                        className="amount" 
                                        value={settings.token0.value || ''} 
                                        placeholder="0.00" 
                                    />
                                    <div className="selected-coin">
                                        <img className="icon" src={ settings.token0.imageUrl } alt="" />
                                        <h4 className="currency">{settings.token0.symbol}</h4>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex-1">
                                        <div className="balance" style={{ fontWeight: '400' }}>${ExchangeService.getTokenDollarPrice(settings.token0.value, settings.token0.symbol)}</div>
                                    </div>
                                    <div className="swap-tokens-dd">
                                        <div className="balance" style={{ fontWeight: '400' }}>Balance: {auth.info.loader || settings.token0.loader ? <Loader style={{ marginLeft: 10, display: 'inline-block' }} width={20} /> : settings.token0.balance}</div>
                                    </div>
                                </div>
                                {/* <div className="flex items-center justify-between mb-1.5">
                                    <p className="balance">{settings.token0.balance} <span>{settings.token0.symbol}</span></p>
                                    {(auth.info.loader || settings.token0.loader) && <div>
                                        <Loader width={24} />
                                    </div>}
                                </div> */}
                            </div>
                            <div className="swapable">
                                <div className="flex items-center justify-between mb-1.5">
                                    <Input 
                                        onChange={(e) => onInputChange(e, 'token1')} 
                                        min={'0'} 
                                        type="number" 
                                        className="amount" 
                                        value={settings.token1.value || ''} 
                                        placeholder="0.00" 
                                    />
                                    <div className="selected-coin">
                                        <img className="icon" src={ settings.token1.imageUrl } alt="" />
                                        <h4 className="currency">{settings.token1.symbol}</h4>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex-1">
                                        <div className="balance" style={{ fontWeight: '400' }}>${ExchangeService.getTokenDollarPrice(settings.token1.value, settings.token1.symbol)}</div>
                                    </div>
                                    <div className="swap-tokens-dd">
                                        <div className="balance" style={{ fontWeight: '400' }}>Balance: {auth.info.loader || settings.token1.loader ? <Loader style={{ marginLeft: 10, display: 'inline-block' }} width={20} /> : settings.token1.balance}</div>
                                    </div>
                                </div>
                                {/* <div className="flex items-center justify-between mb-1.5">
                                    <p className="balance">{settings.token1.balance} <span>{settings.token1.symbol}</span></p>
                                    {(auth.info.loader || settings.token1.loader) && <div>
                                        <Loader width={24} />
                                    </div>}
                                </div> */}
                            </div>
                            <button className="btn-swap cursor-default">
                                <span style={{ fontSize: 22 }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.375 10C19.375 10.2984 19.2565 10.5845 19.0455 10.7955C18.8345 11.0065 18.5484 11.125 18.25 11.125H11.125V18.25C11.125 18.5484 11.0065 18.8345 10.7955 19.0455C10.5845 19.2565 10.2984 19.375 10 19.375C9.70163 19.375 9.41548 19.2565 9.2045 19.0455C8.99353 18.8345 8.875 18.5484 8.875 18.25V11.125H1.75C1.45163 11.125 1.16548 11.0065 0.954505 10.7955C0.743526 10.5845 0.625 10.2984 0.625 10C0.625 9.70163 0.743526 9.41548 0.954505 9.2045C1.16548 8.99353 1.45163 8.875 1.75 8.875H8.875V1.75C8.875 1.45163 8.99353 1.16548 9.2045 0.954505C9.41548 0.743526 9.70163 0.625 10 0.625C10.2984 0.625 10.5845 0.743526 10.7955 0.954505C11.0065 1.16548 11.125 1.45163 11.125 1.75V8.875H18.25C18.5484 8.875 18.8345 8.99353 19.0455 9.2045C19.2565 9.41548 19.375 9.70163 19.375 10Z" fill="#717179"/>
                                    </svg>
                                </span>
                                {/* <img className="icon" src="/assets/icons/icon-multi-direction.svg" alt="" /> */}
                            </button>
                        </div>

                        <div className="collapsible-pool-details mt-3.5 md:mt-9 mb-5 md:mb-4" data-accordion="collapse">
                            <button onClick={() => setState({ ...state, removeLiquidity: !state.removeLiquidity })}
                                type="button" data-accordion-target="#collapsible-details" ariaaria-expanded="true"
                                aria-controls="collapsible-details" className="collapse-header"
                            >
                                <span>View pool details</span>
                                <svg data-accordion-icon className={"w-6 h-6 shrink-0 " + (!state.removeLiquidity && 'rotate-180')}
                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                            <div id="collapsible-details" className={state.removeLiquidity && 'hidden'}>
                                {/* <div className="flex items-center justify-between mb-2.5 md:mb-3">
                                    <p className="key text-white">Free APR</p>
                                    <p className="value text-dark">-0.02%</p>
                                </div> */}
                                <div className="flex items-center justify-between mb-2.5 md:mb-3">
                                    <div className="key direction-row flex items-center">
                                        <p className="text text-white">Slippage Tolerance</p>
                                        <img 
                                            data-tooltip-id="tooltip" 
                                            data-tooltip-content="Transaction will revert if the price changes unfavourably by more than this percentage" 
                                            className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                                    </div>
                                    {/* <p className="key text-white">Slippage Tolerance</p> */}
                                    {/* <p className="value text-dark">-0.02%</p> */}
                                    <h6 className="value text-success">{settings.slippage}%</h6>
                                </div>
                                <div className="flex items-center justify-between mb-2.5 md:mb-3">
                                    <div className="key direction-row flex items-center">
                                        <p className="text text-white">LP Token Output</p>
                                        <img 
                                            data-tooltip-id="tooltip" 
                                            data-tooltip-content="LP Token Amount you are guaranteed to receive" 
                                            className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                                    </div>
                                    { settings.lptokenOutputLoader ? (
                                        <Loader />
                                    ) : (
                                        <p className="value text-white">{ settings.lptokenOutput }</p>
                                    )}
                                </div>
                                {/* <hr className="my-3" />
                                <div className="flex items-center justify-between">
                                    <p className="key text-warning">Rewards</p>
                                    <p className="value text-warning">~$3.62</p>
                                </div> */}
                            </div>
                        </div>
                        { !auth.isLoggedIn && (
                            <>
                                <button 
                                    onClick={() => {
                                        setLayoutState({
                                            showSideBar: !layoutState.showSideBar,
                                            showWalletSidebar: !layoutState.showWalletSidebar
                                        })
                                    }}
                                    className="btn-warning">
                                    Connect Wallet
                                </button>
                                {/* <button className="btn-dark">Connect wallet</button> */}
                                {/* <button className="btn-insufficient">Insufficient Balance</button> */}
                            </>
                        )}
                        { auth.isLoggedIn && (
                            (isLoading || noValue) ? (
                                <button className={"disabled btn-warning"}>Confirm</button>
                            ) : isInsufficient ? (
                                <button className="btn-insufficient">Insufficient Balance</button>
                            ) : (
                                <button onClick={onAddLiquidity} className={(noValue ? 'disabled ' : ' ') + "btn-warning"}>Confirm</button>
                            )
                        )}
                        {/* <button className="btn-dark" disabled>Confirm</button> */}
                    </div>
                )}
                {state.tab === Tabs.MyLiquidity && (
                    <div className="my-liquidity">
                        <div className="swap-container relative">
                            <div className="swapable mb-2.5 md:mb-4">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="amount">{ getTokenBasedExcatValue(settings.calcBurn.amt0, settings.token0.vsymbol) }</h4>
                                        {/* <p className="balance">{ settings.token0.balance } { settings.token0.symbol }</p> */}
                                        <p className="balance">${ ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(settings.calcBurn.amt0, settings.token0.vsymbol), settings.token0.symbol) }</p>
                                    </div>
                                    <div>
                                        <div className="selected-coin">
                                            <img className="icon" src={ settings.token0.imageUrl } alt="" />
                                            <h4 className="currency">{ settings.token0.symbol }</h4>
                                        </div>
                                        <p className="fee">50%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="swapable">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="amount">{ getTokenBasedExcatValue(settings.calcBurn.amt1, settings.token1.vsymbol) }</h4>
                                        <p className="balance">${ ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(settings.calcBurn.amt1, settings.token1.vsymbol), settings.token1.symbol) }</p>
                                        {/* <p className="balance"> ${ ExchangeService.getTokenDollarPrice(settings.calcBurn.amt1, settings.token1.symbol) }</p> */}
                                    </div>
                                    <div>
                                        <div className="selected-coin">
                                            <img className="icon" src={ settings.token1.imageUrl } alt="" />
                                            <h4 className="currency">{ settings.token1.symbol }</h4>
                                        </div>
                                        <p className="fee">50%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="collapsible-pool-details mt-4 md:mt-6" data-accordion="collapse">
                            <button onClick={() => setState({ ...state, removeLiquidity: !state.removeLiquidity })} className="collapse-header"
                                type="button" data-accordion-target="#collapsible-details" ariaaria-expanded="true"
                                aria-controls="collapsible-details"
                            >
                                <div className='flex items-center justify-between'>
                                    <span>Remove Liquidity</span>
                                    {/* {settings.calcBurn.loader && (
                                        <div className='ml-3'>
                                            <Loader width={20} />
                                        </div>
                                    )} */}
                                </div>
                                <svg data-accordion-icon className={"w-6 h-6 shrink-0 " + (!state.removeLiquidity && 'rotate-180')}
                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fillRule="evenodd"
                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                            <div id="collapsible-details" className={state.removeLiquidity && 'hidden'}>
                                <div className="balance-bar">
                                    <p className="key">LP Balance</p>
                                    <p className="value">{ lpTokenValue  }</p>
                                </div>
                                <LiquiditySlider
                                    value={settings.sliderValue}
                                    onComplete={(val) => {
                                        if (auth.isLoggedIn) {
                                            onSliderComplete(val);
                                        } else {
                                            setLayoutState({
                                                ...layoutState,
                                                showSideBar: !layoutState.showSideBar,
                                                showWalletSidebar: !layoutState.showWalletSidebar
                                            })
                                        }
                                    }} />
                                <div className="will-remove-card">
                                    <h6 className="header">You will remove { val > 0 ? Number(val).toFixed(0) : '' }</h6>
                                    <div className="flex items-center justify-between px-3 py-2.5">
                                        <p className="key text-white">{settings.token0.name}</p>
                                        {settings.calcBurnChange.loader ? (
                                            <p className="value text-dark"><Loader /></p>
                                        ) : (
                                            <p className="value text-dark">{ (settings.calcBurnChange.amt0).toFixed(getTokenDecimalsLength(settings.token0.symbol)) } {settings.token0.symbol}</p>
                                        )}
                                    </div>
                                    <hr />
                                    <div className="flex items-center justify-between px-3 py-2.5">
                                        <p className="key text-white">{settings.token1.name}</p>
                                        {settings.calcBurnChange.loader ? (
                                            <p className="value text-dark"><Loader /></p>
                                        ) : (
                                            <p className="value text-dark">{ (settings.calcBurnChange.amt1).toFixed(getTokenDecimalsLength(settings.token1.symbol)) } {settings.token1.symbol}</p>
                                        )}
                                    </div>
                                </div>
                                { !auth.isLoggedIn && (
                                    <>
                                        <button 
                                            onClick={() => {
                                                setLayoutState({
                                                    showSideBar: !layoutState.showSideBar,
                                                    showWalletSidebar: !layoutState.showWalletSidebar
                                                })
                                            }}
                                            className="btn-warning h-11 text-sm mt-4 md:mt-6">
                                            Connect Wallet
                                        </button>
                                        {/* <button className="btn-dark">Connect wallet</button> */}
                                        {/* <button className="btn-insufficient">Insufficient Balance</button> */}
                                    </>
                                )}
                                { auth.isLoggedIn && (
                                    <button onClick={onRemoveLiquidity} className="btn-danger h-11 text-sm mt-4 md:mt-6">
                                        { state.removeLoader ? (
                                            <Loader />
                                        ) : (
                                            'Remove Liquidity'
                                        ) }
                                    </button>
                                )}
                            </div>
                        </div>
                        {/* <div className="claim-rewards-card"> */}
                            {/* <h6 className='header'>Claim Rewards</h6> */}
                            {/* <div className="flex items-center justify-between mb-3">
                                <p className="key text-white">{ settings.token0.name } Fees</p> 
                                <p className="value text-dark">14.372 { settings.token0.symbol }</p>
                            </div> */}
                            {/* <div className="flex items-center justify-between">
                                <p className="key text-white">{ settings.token1.name }</p> 
                                <p className="value text-dark">14.372 { settings.token1.symbol }</p>
                            </div> */}
                            {/* <hr className="my-3" /> */}
                            {/* <div className="flex items-center justify-between">
                                <p className="key text-warning">Velar Rewards</p> 
                                <p className="value text-warning">3467 VEL</p>
                            </div> */}
                            {/* { auth.isLoggedIn && (
                                <button className="btn-warning h-11 text-sm mt-4">Claim rewards</button>
                            )} */}
                            {/* { !auth.isLoggedIn && (
                                <>
                                    <button 
                                        onClick={() => {
                                            setLayoutState({
                                                showSideBar: !layoutState.showSideBar,
                                                showWalletSidebar: !layoutState.showWalletSidebar
                                            })
                                        }}
                                        className="btn-warning h-11 text-sm mt-4">
                                        Connect Wallet
                                    </button>
                                    <button className="btn-dark">Connect wallet</button>
                                    <button className="btn-insufficient">Insufficient Balance</button>
                                </>
                            )} */}
                        {/* </div> */}
                    </div>
                )}
            </div>

            {settings.transactionModal && (
                <TransactionSettingsModal
                    setState={setSettings}
                    state={settings}
                    onChange={(t) => {
                        setSettings({ ...settings, slippage: t })
                    }}
                    onClose={() => {
                        setSettings({ ...settings, transactionModal: false });
                        toggleBodyScroll(false)
                    }} />
            )}

            {settings.successfulModal && (
                <SuccessfulModal
                    forType={'pool'}
                    message={state.message}
                    state={settings}
                    swap1={{ ...settings.token0, value: state.tempVal1 }}
                    swap2={{ ...settings.token1, value: state.tempVal2 }}
                    onClose={() => { 
                        settings.token1.value = '';
                        settings.token0.value = '';
                        setSettings({ ...settings, successfulModal: false });
                        toggleBodyScroll(false) 
                    }} 
                />
            )}
        </div>

    );
}
