import { useContext, useEffect, useState } from "react"
import { Navbar } from "../components/Navbar"
import { RightSideBar } from "../components/RightSideBar"
import { LayoutContext } from '../contexts/LayoutContext';
import { useDispatch, useSelector } from "react-redux";
import { getTransactions, setTransactionIntervalLoader, setTransactionLoader } from "../store/slices/chainSlice";
import { getAccountTokens } from "../store/slices/authSlice";

export const Layout = (props) => {
    const [ layoutState, setLayoutState ] = useState({ showMobileNavModal: false, showNotifications: false, showSideBar: false,  showWalletSidebar: false });
    const dispatch = useDispatch();
    const auth = useSelector((x) => x.auth);
    const chain = useSelector((x) => x.chain);
    const txMeta = chain?.transaction?.data?.meta?.transactions || { offset: 0, total: 0 };

    useEffect(() => {
        let interval = setInterval(() => {
          if (!txMeta.offset && auth.isLoggedIn && !chain.transaction.intervalLoader && !chain.transaction.loader) {
            dispatch(setTransactionIntervalLoader(true));
            dispatch(getTransactions());
            dispatch(getAccountTokens());
          }
        }, 10000);
        return  () => clearInterval(interval);
      }, [txMeta])

    return (
        <div>
            <LayoutContext.Provider value={{ layoutState, setLayoutState }}>
                <Navbar {...props} />
                {/* { (layoutState.showNotifications) && <div onClick={() => setLayoutState({ ...layoutState, showMobileNavModal: false, showSideBar: false, showNotifications: false })} className="full-wrapper"></div> } */}
                <RightSideBar {...props} />
                <div className="container mx-auto px-4">
                    { props.children }
                </div>
            </LayoutContext.Provider>
        </div>
    )
}