import { useDispatch, useSelector } from "react-redux"
import { SwapComponent } from "../components/SwapComponent"
import Breadcrumbs from "../components/Breadcrumbs";
import { openContractCall } from '@stacks/connect';
import { uintCV, standardPrincipalCV, cvToHex, cvToJSON, hexToCV } from '@stacks/transactions';
import { StacksDevnet } from '@stacks/network'
import { useEffect } from "react";
import { ContractService } from "../services/ContractService";
import { getTokens } from "../store/slices/swapSlice";
import { getAccountTokens, setTokenLoader } from "../store/slices/authSlice";
import CreateContractService from "../services/CreateContractService";
import { getCryptocurrency } from "../store/slices/marketSlice";
import ChainService from "../services/ChainService";
import { useLocation, useNavigate } from "react-router-dom";
import { getPools, setPoolLoader } from "../store/slices/poolSlice";
import { LiquidityPool } from "./LiquidityPool";

export const Home = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector((x) => x.auth);
    const swap = useSelector((x) => x.swap);
    const navigate = useNavigate();
    
    useEffect(() => {
        dispatch(getCryptocurrency());
        // dispatch(setTokenLoader(true));
        // dispatch(getTokens());
        // dispatch(setPoolLoader(true))
        // dispatch(getPools());
        navigate("/pool");
    }, [])

    const init = async () => {
        // await CreateContractService.mint(
        //     'ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.wstx',
        //     'ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.velar',
        //     'ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM.wstx-velar',
        //     1,
        //     1,
        //     1
        // )
    }

    return (
        <>
            <LiquidityPool { ...props } />
        </>

    )
}