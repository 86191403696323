import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export default function MyFarmListView(props) {

    const { liquidities } = props;

    return (
        <div className="pool-list">
            <div className="overflow-x-auto">
                <table className='pool-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Staked</th>
                            <th>Rewards</th>
                            <th>APR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {liquidities.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Link to={`/farm/` + item.symbol} className="conversion">
                                        <div className="icons">
                                            <img
                                                className="z-6"
                                                src={item.token0ImageUrl}
                                                alt="coin two" />
                                            <img
                                                className="z-7"
                                                src={item.token1ImageUrl}
                                                alt="coin one" />
                                        </div>
                                        <div className="coins">{ item.name }</div>
                                        {/* <div className="fee">0.3%</div> */}
                                    </Link>
                                </td>
                                {/* <td>{ item.farm?.total }</td> */}
                                <td style={{ width: '20%' }}>{ item.farm?.user }</td>
                                <td style={{ width: '20%' }}>-</td>
                                <td>
                                    <div className="flex items-center">
                                        {/* <img className='mr-1.5' src="/assets/icons/fire.svg" alt="fire" />
                                        <span>10.95%</span> */}
                                        --
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
