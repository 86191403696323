import { Route, Router, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from "./pages/Layout";
import { Home } from "./pages/Home";
import { Pool } from "./pages/Pool";
import { Stake } from "./pages/Stake";
import { IDO } from "./pages/IDO";
import { Farm } from "./pages/Farm";
import { Faucet } from "./pages/Faucet";
import { Notifications } from "./pages/Notifications";
import 'react-rangeslider/lib/index.css';
import { Detail } from "./components/IDO/Detail";
import { FarmStake } from "./components/Farm/FarmStake";
import { getAccountTokens, setCoinLoader, setTokenLoader, updateAuth } from "./store/slices/authSlice";
import { LiquidityPool } from "./pages/LiquidityPool";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import { getTokens, setSwapTokenLoader } from "./store/slices/swapSlice";
import { getPools, setPoolLoader } from "./store/slices/poolSlice";
import { AggrementModal } from "./modals/AggrementModal";
import { AppConstants } from "./constants";
import { addScript } from "./helper";
import { Config } from "./config";

function App() {
  const [state, setState] = useState({ aggrement: false });
  const dispatch = useDispatch();
  const location = useLocation();

  if (localStorage.getItem('wallet-state')) {
    const authData = JSON.parse(localStorage.getItem('wallet-state'));
    dispatch(updateAuth({ isLoggedIn: true, authData: authData }));
  }

  useEffect(() => {
    if (Config.name === AppConstants.Networks.MAINNET) {
      const onPageLoad = () => {
        setTimeout(() => {
          const authData = JSON.parse(localStorage.getItem('wallet-state'));
          if (authData) {
            const walletProvider = authData.wallet === AppConstants.Wallets.Hiro ? AppConstants.Hiro.ID : AppConstants.XVerse.ID;
            const script = document.getElementById(walletProvider);
            if (script) {
              addScript(script.src).then({});
            }
          }
        }, 1000);
      };
  
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad);
        return () => window.removeEventListener('load', onPageLoad);
      }
    }
  }, [])

  useEffect(() => {
    let interval = setInterval(() => {
      dispatch(setTokenLoader(true));
      dispatch(setCoinLoader(true))
      dispatch(getAccountTokens());
    }, 60000)

    return () => {
      clearInterval(interval);
    }
  }, [])

  const init = async () => {
      dispatch(setTokenLoader(true));
      dispatch(setSwapTokenLoader(true));
      dispatch(getAccountTokens());
      dispatch(getTokens());
      dispatch(setPoolLoader(true))
      dispatch(getPools());
  }

  useEffect(() => {
    if (Config.name === AppConstants.Networks.MAINNET) {
      const aggrement = localStorage.getItem('velar-aggrement');
      setState({ ...state, aggrement: Boolean(aggrement) });
    }

    init();
  }, [location.pathname])
  
  return (
    <div className="velar-app relative">
      <a href="https://app.velar.co" className="beta-banner">
        <span>Velar Dharma mainnet is live. Try now </span>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1131 4C11.5153 5.03704 12.7748 6.24852 13.8603 7.60379C13.9534 7.72005 14 7.86002 14 8M10.1131 12C11.5153 10.963 12.7748 9.75148 13.8603 8.39621C13.9534 8.27995 14 8.13998 14 8M14 8H2" stroke="#111111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>

      <Layout>
        {/* {(!state.aggrement && Config.name == AppConstants.Networks.MAINNET) && (
          <AggrementModal onClose={(e) => {
            setState({ aggrement: true })
          }} />
        )} */}
        <Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/faucet" element={ <Faucet /> } />
            {/* <Route path="/swap" element={ <Home /> } /> */}
            <Route path="/pool/:id" element={ <Pool /> } />
            {/* <Route element={<PrivateRoutes />} /> */}
            <Route path="/pool" element={ <LiquidityPool /> } exact />
            <Route path="/stake" element={ <Stake /> } />
            {/* <Route path="/ido" element={ <IDO /> } /> */}
            {/* <Route path="/ido/details" element={ <Detail /> } /> */}
            <Route path="/farm/:id" element={ <FarmStake /> } />
            <Route path="/farm" element={ <Farm /> } />
            <Route path="/notifications" element={ <Notifications /> } />
        </Routes>
      </Layout>
      <ReactTooltip id="tooltip" place="bottom" />
    </div>
  );
}

export default App;
