import { Link, useParams, useRoutes } from 'react-router-dom'
import Breadcrumbs from "../components/Breadcrumbs"
import Chart from '../components/Charts/Chart'
import Summary from '../components/Summary'
import PoolHeader from '../components/Pool/PoolHeader'
import Liquidity from '../components/Liquidity'
import CoreContractService from '../services/CoreContractService'
import { useCallback, useEffect, useRef, useState } from 'react'
import PoolService from '../services/PoolService'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountTokens, setTokenLoader } from '../store/slices/authSlice'
import { getTokens } from '../store/slices/swapSlice'
import { useConnect } from '../hooks/useConnect'
import PoolContractService from '../services/PoolContractService'
import debounce from 'lodash/debounce'
import { commarize, getTokenBasedExcatValue } from '../helper'
import Loader from '../components/Loader'
import ExchangeService from '../services/ExchangeService';
import { getPoolStats, setStatsLoader } from '../store/slices/poolSlice'
import { AppConstants } from '../constants'

export const Pool = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const swap = useSelector((x) => x.swap);
    const auth = useSelector((x) => x.auth);
    const connect = useConnect();
    const balance = connect.useBalance();
    const [ totalSupply, setTotalSupply ] = useState(0);
    const [ settings, setSettings ] = useState({
        isDirty: false,
        slippage: 4,
        transactionModal: false,
        lptokenOutput: 0,
        lptokenOutputLoader: false,
        transactionUrl: '',
        calcBurnChange: {
            init: false,
            inProcess: false,
            loader: false,
            amt0: 0,
            amt1: 0
        },
        calcBurn: {
            init: false,
            inProcess: false,
            loader: false,
            amt0: 0,
            amt1: 0
        },
        sliderValue: 0,
        successfulModal: false,
        poolInfo: {},
        pool: {
            reserve0: { value: 0 },
            reserve1: { value: 0 },
        },
        qoute: 0,
        token0: { 
            imageUrl: "/placeholder.jpg",
            value: '',
            balance: '0.0', 
            name: '', 
            symbol: '', 
            contractAddress: '', 
            loader: false, 
            autoFilled: false 
        },
        token1: { 
            imageUrl: "/placeholder.jpg",
            value: '',
            balance: '0.0', 
            name: '', 
            symbol: '', 
            contractAddress: '', 
            loader: false, 
            autoFilled: false 
        }
    })
    const settingRef = useRef(settings);
    const authRef = useRef(auth);


    useEffect(() => {
        settingRef.current = settings;
        authRef.current = auth;
    })
  
    useEffect(() => {
        if (Number(settings.poolInfo?.id) > 0) {
            dispatch(getPoolStats({ interval: '24', poolId: Number(settings.poolInfo?.id) }));
        }
    }, [settings.poolInfo?.id])

    useEffect(() => {
        dispatch(setTokenLoader(true));
        dispatch(setStatsLoader(true));
        dispatch(getAccountTokens());
        dispatch(getTokens());
        init ();
        if (auth?.isLoggedIn) {
            const interval = setInterval(() => {
                fetchInInterval(settingRef.current, auth);
            }, 20000);
    
            return () => {
                clearInterval(interval);
            }
        }
    }, []);

    const handleSliderComplete = useCallback(debounce(async (val, _settings, _auth) => { 
        setSettings({ ..._settings, calcBurnChange: { init: _settings.calcBurnChange.init, loader: true, amt0: Number(0), amt1: Number(0) } })
        try {
            const poolContractData = await CoreContractService.getPool(_settings.poolInfo.id);
            const poolData = poolContractData?.value?.data || {};
            const reserve0 = poolData['reserve0'].value;
            const reserve1 = poolData['reserve1'].value;
            const lpTokenValue = Number(_auth.info.tokens.find((x) => x.contract === _settings.poolInfo.lpTokenContractAddress)?.balance || 0);
            const val =  Number(lpTokenValue * (_settings.sliderValue / 100));
            let result = await CoreContractService.calcBurn(
                val,
                reserve0,
                reserve1,
                totalSupply
            );
            
            let amtO = getTokenBasedExcatValue((result?.amt0 || 0), _settings.token0.symbol);
            let amt1 = getTokenBasedExcatValue((result?.amt1 || 0), _settings.token1.symbol);

            setSettings({ ..._settings, calcBurnChange: { init: true, loader: false, amt0: amtO, amt1: amt1 } })
        } catch (error) {
            console.log(error);
            setSettings({ ..._settings, calcBurnChange: { init: true, loader: false, amt0: Number(0), amt1: Number(0) } })
        }

    }, 600), [settings.sliderValue, settings, totalSupply, auth]);


    const updateTokenBalances = (xstate) => {
        const token0 = auth.info.tokens?.find((x) => x.contract === xstate.token0.contractAddress)?.balance || 0;
        if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(xstate?.token0?.symbol?.toLowerCase())) {
            xstate.token0.balance = balance;
        } else {
            xstate.token0.balance = getTokenBasedExcatValue(token0, xstate?.token0?.symbol);
        }
        const token1 = auth.info.tokens?.find((x) => x.contract === xstate.token1.contractAddress)?.balance || 0;
        if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(xstate.token1?.symbol?.toLowerCase())) {
            xstate.token1.balance = balance;
        } else {
            xstate.token1.balance = getTokenBasedExcatValue(token1, xstate?.token1?.vsymbol);
        }

        setSettings({ ...settings, ...xstate });
    }

    useEffect(() => {
        const tokens = swap.tokens.message;
        const token0Address = settings?.poolInfo?.token0ContractAddress;
        const token1Address = settings?.poolInfo?.token1ContractAddress;

        const token0 = tokens.find((x) => x.contractAddress == token0Address);
        const token1 = tokens.find((x) => x.contractAddress == token1Address);

        if (token0 && token1) {
            settings.token0 = { ...token0, balance: 0.0, value: '' };
            settings.token1 = { ...token1, balance: 0.0, value: '' };
        }
        updateTokenBalances(settings);
    }, [swap.tokens.message.length, auth.info?.tokens?.length, balance, settings.poolInfo]);


    const fetchInInterval = async (__settings) => {
        const _settings = __settings || settingRef.current;
        const _auth = authRef.current;

        if (_settings.calcBurn.inProcess) { return; }

        if (_settings.pool['reserve0'] && _settings.pool['reserve1']) {
            const lpTokenValue = _auth.info.tokens.find((x) => x.contract === _settings.poolInfo.lpTokenContractAddress)?.balance || 0;
            setSettings((settings) => ({ ...settings, calcBurn: { init: _settings.calcBurn.init, inProcess: true, loader: true, amt0: _settings.calcBurn.amt0, amt1: _settings.calcBurn.amt1 } }))
            const totalSupply = await PoolContractService.getTotalSupply(_settings.poolInfo.lpTokenContractAddress);

            const poolContractData = await CoreContractService.getPool(_settings.poolInfo.id);
            const poolData = poolContractData?.value?.data || {};
            // setSettings((settings) => ({ ...settings, pool: { ...poolData } }));

            try {
                const pool = _settings.pool;
                const reserve0 = pool['reserve0'].value;
                const reserve1 = pool['reserve1'].value;
        
                let result = await CoreContractService.calcBurn(
                    Number(lpTokenValue),
                    reserve0,
                    reserve1,
                    totalSupply
                );

                let amtO = Number(result?.amt0 || 0); //getTokenBasedExcatValue((result?.amt0 || 0), _settings.token0.symbol);
                let amt1 = Number(result?.amt1 || 0); //getTokenBasedExcatValue((result?.amt1 || 0), _settings.token1.symbol);

                setSettings((settings) => ({ ...settings,  pool: { ...poolData }, calcBurn: { init: true, inProcess: false, loader: false, amt0: amtO, amt1: amt1 } }));
            } catch (error) {
                console.log(error);
                setSettings((settings) => ({ ...settings,  pool: { ...poolData }, calcBurn: { init: true, inProcess: false, loader: false, amt0: Number(0), amt1: Number(0) } }));
            }

            // handleSliderComplete(settings.sliderValue, settings)
            setTotalSupply(totalSupply);
        }
    }

    const init = async () => {
        const poolInfo = await PoolService.getPoolBySymbol(params.id);
        const poolContractData = await CoreContractService.getPool(poolInfo.message.id);
        const poolData = poolContractData?.value?.data || {};
        settings.pool = { ...poolData };
        settings.poolInfo = poolInfo.message;
        setSettings({ ...settings });
        settingRef.current = settings;
        await fetchInInterval(settings);
    }
    

    const printPoolCompositionDollarValue = (type = 'all') => {
        const r1 = ExchangeService.getTokenDollarPrice(
            getTokenBasedExcatValue(settings.pool?.reserve0?.value, settings.token0.vsymbol),
            settings.token0.symbol,
            false
        );

        if (type == 'r1') { return r1; }

        const r2 = ExchangeService.getTokenDollarPrice(
            getTokenBasedExcatValue(settings.pool?.reserve1?.value, settings.token1.vsymbol),
            settings.token1.symbol,
            false
        );

        if (type == 'r2') { return r2; }

        return Number(r1) + Number(r2);
    }

    const printMyCompositionDollarValue = (type = 'all') => {
        const r1 = ExchangeService.getTokenDollarPrice(
            getTokenBasedExcatValue(settings.calcBurn.amt0, settings.token0.vsymbol),
            settings.token0.symbol,
            false
        );
            
        if (type == 'r1') { return Number(r1); }

        const r2 = ExchangeService.getTokenDollarPrice(
            getTokenBasedExcatValue(settings.calcBurn.amt1, settings.token1.vsymbol),
            settings.token1.symbol,
            false
        );

        if (type == 'r2') { return Number(r2); }

        return (Number(r1) + Number(r2));
    }


    return (
        <div className="single-pool max-w-950"> 
            {/* <PoolHeader settings={settings} /> */}
            <div className="grid grid-cols-2 gap-x-0 md:gap-x-6 gap-y-6 md:gap-y-0">
                <Liquidity handleSliderComplete={handleSliderComplete} totalSupply={totalSupply} settings={settings} setSettings={setSettings} />
                <div className="col-span-2 md:col-span-1">
                    <div className="position-card">
                        <div className="header">
                            <h6 className="heading">My Position</h6>
                            <div className='flex items-center justify-between'>
                                { (!settings.calcBurn.init && settings.calcBurn.inProcess) && <Loader width={22} /> }
                                <p className="value ml-3">${ commarize(printMyCompositionDollarValue()) }</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mb-3.5">
                            <div className="coin flex items-center">
                                <img className='w-5 mr-2' src={settings.token0.imageUrl} alt="coin-two" />
                                <h6 className="name">{ getTokenBasedExcatValue(settings.calcBurn.amt0, settings.token0.symbol) } { settings.token0.name }</h6> 
                            </div>
                            <p className="amount">${ commarize(printMyCompositionDollarValue('r1')) }</p>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="coin flex items-center">
                                <img className='w-5 mr-2' src={settings.token1.imageUrl} alt="coin-two" />
                                <h6 className="name">{ getTokenBasedExcatValue(settings.calcBurn.amt1, settings.token1.symbol) } { settings.token1.name }</h6> 
                            </div>
                            <p className="amount">${ commarize(printMyCompositionDollarValue('r2')) }</p>
                        </div>
                    </div>
                    <div className="position-card mt-3.5">
                        <div className="header">
                            <h6 className="heading">Pool Composition</h6>
                            <p className="value">${ commarize(printPoolCompositionDollarValue()) }</p>
                        </div>
                        <div className="flex items-center justify-between mb-3.5">
                            <div className="coin flex items-center">
                                <img className='w-5 mr-2' src={settings.token0.imageUrl} alt="coin-two" />
                                <h6 className="name">{ Number(getTokenBasedExcatValue(settings.pool?.reserve0?.value, settings.token0.symbol)) } { settings.token0.name }</h6> 
                            </div>
                            <p className="amount">${ commarize(printPoolCompositionDollarValue('r1')) }</p>
                            {/* <p className="amount">${ commarize(((Number(settings.pool?.reserve0?.value)) * 0.68)) }</p> */}
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="coin flex items-center">
                                <img className='w-5 mr-2' src={settings.token1.imageUrl} alt="coin-two" />
                                <h6 className="name">{ Number(getTokenBasedExcatValue(settings.pool?.reserve1?.value, settings.token1.symbol)) || 0 } { settings.token1.name }</h6> 
                            </div>
                            <p className="amount">${ commarize(printPoolCompositionDollarValue('r2')) }</p>
                        </div>
                    </div>

                    <div className="in-case-card mt-3.5">
                        <h3 className='header'>
                            <img className='w-4 mr-2.5' src="/assets/icons/warning.svg" alt="warning" />
                            Incase you didn't know
                        </h3>
                        <p className='desc'>
                            When you provide liquidity, you get LP tokens which earn fees and can be redeemed later.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}