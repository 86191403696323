import Breadcrumbs from "../components/Breadcrumbs";
import StakeUnstakeProcess from "../components/StakeUnstakeProcess";

export const Stake = (props) => {
    
    return (
        <div className="stake-main">
            <div className="global-page-header">
                <h2 className="title">Staking</h2>
                <h6 className="desc">Stake VELAR and earn multiple rewards</h6>
                {/* <form>
                    <input type="search" className="header-searchbar" placeholder="Search token, token pairs etc.." />
                </form> */}
            </div>
            <StakeUnstakeProcess />
        </div>
    )
}