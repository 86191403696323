import { useEffect, useState } from "react";
import Search from "../components/Liquidity/Search";
import GridView from "../components/Farm/GridView";
import ListView from "../components/Farm/ListView";
import Breadcrumbs from "../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getPools } from "../store/slices/poolSlice";
import Loader from "../components/Loader";
import { AppConstants } from "../constants";
import { setListView } from "../store/slices/appSlice";
import MyFarmListView from "../components/Farm/MyFarmListView";
import MyFarmGridView from "../components/Farm/MyFarmGridView";

export const Tabs = {
    ALL: 'all',
    MY: 'my'
}

export const Farm = (props) => {
    const dispatch = useDispatch();
    const pool = useSelector((x) => x.pool);
    const app = useSelector((x) => x.app);
    const swap = useSelector((x) => x.swap);
    const [ searchSelected, setSearchSelected ] = useState(null);
    const [ tab, setTab ] = useState(Tabs.ALL);

    useEffect(() => {
        dispatch(getPools({ context: 'farm' }));
    }, []);


    const [state, setState] = useState({
        isListView : true,
        isGridView : false,
    });

    const view = AppConstants.Views[app.listView] || AppConstants.Views.LIST;

    const poolData = pool.pools.message?.filter((x) => {
        if (!searchSelected) {
            return x;
        } else if (x.symbol?.toLowerCase().indexOf(searchSelected?.symbol?.toLowerCase()) > -1) {
            return x;
        }
    }).filter(Boolean);

    const positions = poolData?.filter((x) => {    
        if (x.farm?.user > 0) {
            return x;
        }
    }).filter(Boolean);

    return (
        <section className="pool-main max-w-950">
            <div className="global-page-header">
                <h2 className="title">Farms</h2>
                <h6 className="desc">Stake and earn rewards</h6>
                <Search
                    selected={searchSelected}
                    onChange={(item) => {
                        setSearchSelected(item)
                    }} data={swap?.tokens?.message || []} />            </div>
            <div className='flex items-center justify-between px-3 pt-1 pb-4'>
                <div className="pool-tabs">
                    <button onClick={() => setTab(Tabs.ALL)} className={"tab-item " + (tab === Tabs.ALL ? 'active' : '')}>Farms</button>
                    <button onClick={() => {
                        if (positions.length > 0) {
                            setTab(Tabs.MY)
                        }
                    }} className={ "tab-item " + (tab === Tabs.MY ? 'active' : '')}>
                        My farms
                        <span className="count">{ positions.length }</span>
                    </button>
                </div>
                <div className="view-filters" role="group">
                    {pool.poolsLoader && (
                        <div className="flex mr-3 items-center justify-center">
                            <Loader width={30} />
                        </div>
                    )}
                    <button type="button" className={(view === AppConstants.Views.LIST ? 'active ' : '') + `btn-filter`}
                        onClick={() => dispatch(setListView(AppConstants.Views.LIST)) }>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1.5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                        </svg>
                        <span>List</span>
                    </button>
                    <button
                        type="button"
                        className={(view === AppConstants.Views.GRID ? 'active ' : '') +  `btn-filter`}
                        onClick={() => dispatch(setListView(AppConstants.Views.GRID)) }>
                        <svg fill="none" viewBox="0 0 20 14" strokeWidth="1" stroke="currentColor" className="w-5 h-5 mr-0.5">
                            <path d="M12.0625 0.8125H1.9375C1.63913 0.8125 1.35298 0.931026 1.142 1.142C0.931026 1.35298 0.8125 1.63913 0.8125 1.9375V12.0625C0.8125 12.3609 0.931026 12.647 1.142 12.858C1.35298 13.069 1.63913 13.1875 1.9375 13.1875H12.0625C12.3609 13.1875 12.647 13.069 12.858 12.858C13.069 12.647 13.1875 12.3609 13.1875 12.0625V1.9375C13.1875 1.63913 13.069 1.35298 12.858 1.142C12.647 0.931026 12.3609 0.8125 12.0625 0.8125ZM12.0625 6.4375H7.5625V1.9375H12.0625V6.4375ZM6.4375 1.9375V6.4375H1.9375V1.9375H6.4375ZM1.9375 7.5625H6.4375V12.0625H1.9375V7.5625ZM12.0625 12.0625H7.5625V7.5625H12.0625V12.0625Z" fill="#717179" />
                        </svg>
                        <span> Grid </span>
                    </button>
                </div>
            </div>
            { tab === Tabs.ALL ? (
                <>
                    {view === AppConstants.Views.LIST && <ListView to={'/farm'} liquidities={poolData} />}
                    {view === AppConstants.Views.GRID && <GridView to={'/farm'} liquidities={poolData} />}
                </>
            ) : (tab === Tabs.MY) && (
                <>
                    {view === AppConstants.Views.LIST && <MyFarmListView to={'/farm'} liquidities={positions} />}
                    {view === AppConstants.Views.GRID && <MyFarmGridView to={'/farm'} liquidities={positions} />}
                </>
            ) }

        </section>
    )
}