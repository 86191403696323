import { BASE_ADDRESS, Config } from "../config";
import {
  buildPostConditions,
  getContractAddressAndName,
  getPureAddress,
  getTokenBasedDecimalValue,
  getTokenDetailsByAddress,
  stringify,
} from "../helper";
import http from "../utils/axiosProvider";
import {
  uintCV,
  contractPrincipalCV,
  cvToHex,
  cvToJSON,
  hexToCV,
  makeStandardSTXPostCondition,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  createAssetInfo,
  makeContractSTXPostCondition,
  makeContractFungiblePostCondition,
  createFungiblePostCondition,
  createSTXPostCondition,
  PostConditionMode,
  principalCV,
} from "@stacks/transactions";
import { ContractService } from "./ContractService";
import { store } from "../store";
import BigNum from "bn.js";
import { AppConstants } from "../constants";
import { getTokenBasedExcatValue } from "../helper";

export class FarmCoreContractService {
  constructor(contractName) {
    const contractService = new ContractService(
      getPureAddress(BASE_ADDRESS),
      contractName
    );
    this.contractService = contractService;
  }

  async getCurrentEpoch () {
    const store = window.velarStore;
    const auth = store.getState().auth;
    if (auth.isLoggedIn) {
      const account = auth.authData.stxAddress.address;
      const result = await this.contractService.callReadOnly('current-epoch', {
        sender: account,
        "arguments": []
      });
      return Number(hexToCV(result.data.result).value);
    }
  }

  async getCalcEpochStart (epoch) {
    const store = window.velarStore;
    const auth = store.getState().auth;
    if (auth.isLoggedIn) {
      const account = auth.authData.stxAddress.address;
      const result = await this.contractService.callReadOnly('calc-epoch-start', {
        sender: account,
        "arguments": [ cvToHex(uintCV(epoch)) ]
      });
      return Number(hexToCV(result.data.result).value);
    }
  }

  async getCalcEpochEnd (epoch) {
    const store = window.velarStore;
    const auth = store.getState().auth;
    if (auth.isLoggedIn) {
      const account = auth.authData.stxAddress.address;
      const result = await this.contractService.callReadOnly('calc-epoch-end', {
        sender: account,
        "arguments": [ cvToHex(uintCV(epoch)) ]
      });
      return Number(hexToCV(result.data.result).value);
    }
  }

  async getUserSahre () {
    const store = window.velarStore;
    const auth = store.getState().auth;
    if (auth.isLoggedIn) {
      const account = auth.authData.stxAddress.address;
      const result = await this.contractService.callReadOnly('get-user-staked', {
        sender: account,
        "arguments": [
          cvToHex(principalCV(account))
        ]
      });
      const stakedResponse = Number(hexToCV(result.data.result).data.end.value);
      return stakedResponse;
    }
  }

  async getTotalStake () {
    const store = window.velarStore;
    const auth = store.getState().auth;
    const address = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
    const result = await this.contractService.callReadOnly('get-total-staked', {
      sender: address,
      "arguments": []
    });
    const stakedResponse = Number(hexToCV(result.data.result).data.end.value);
    return stakedResponse;
  }

  async unStack (amt0, token, transactionPayload, symbol, ownerContract) {
    const store = window.velarStore;
    const auth = store.getState().auth;
    if (auth.isLoggedIn) {
      const account = auth.authData.stxAddress.address;
      const tokenInfo = getContractAddressAndName(token);
      amt0 = getTokenBasedDecimalValue(Number(amt0), tokenInfo.name);
      transactionPayload.txType = 'remove-staking';
      transactionPayload.publicAddress = account;

      const postConditionMeta = [{
        account: tokenInfo.address,
        type: AppConstants.PostConditionType.ContractFungible,
        code: FungibleConditionCode.Equal,
        amount: amt0,
        assetInfo: {
          address: tokenInfo.address,
          contractName: tokenInfo.name,
          name: "lp-token",
        },
        ownerContract: ownerContract
      }];

      const postconditions = buildPostConditions(postConditionMeta);
      const result = await this.contractService.callPublicFunction(
        "unstake",
        [uintCV(amt0)],
        postconditions,
        transactionPayload
      );
      return result;
    }
  }

  async stack(amt0, token, transactionPayload = {}, symbol) {
    const store = window.velarStore;
    const auth = store.getState().auth;
    if (auth.isLoggedIn) {
      const account = auth.authData.stxAddress.address;
      transactionPayload.publicAddress = account;
      transactionPayload.txType = 'add-staking';
      const tokenInfo = getContractAddressAndName(token);
      amt0 = getTokenBasedDecimalValue(Number(amt0), tokenInfo.name);

      const postConditionMeta = [{
        account: account,
        type: AppConstants.PostConditionType.StandardSTXFungible,
        code: FungibleConditionCode.Equal,
        amount: amt0,
        assetInfo: {
          address: tokenInfo.address,
          contractName: tokenInfo.name,
          name: "lp-token",
        }
      }];

      const postconditions = buildPostConditions(postConditionMeta);
      const result = await this.contractService.callPublicFunction(
        "stake",
        [uintCV(amt0)],
        postconditions,
        transactionPayload,
      );
      return result;
    }
  }
}

export default new FarmCoreContractService('wstx-sbtc-farming-core');
