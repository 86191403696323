import { Config } from '../config';
import { getContractAddressAndName, getPureAddress } from '../helper';
import http from '../utils/axiosProvider';
import { uintCV, standardPrincipalCV, cvToHex, cvToJSON, hexToCV } from '@stacks/transactions';
import { ContractService } from './ContractService';

export class LibraryContractService {
    constructor() {
        const { address, name } = getContractAddressAndName(Config.ContractAddresses.Library)
        const contractService = new ContractService(address, name);
        this.contractService = contractService;
    }

    async getAmountOut(amountIn, reserveIn, reserveOut, swapFee, protocolFee) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        const address = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
        amountIn = Number(amountIn).toFixed(0);
        const result = await this.contractService.callReadOnly('get-amount-out', {
            sender: address,
            "arguments": [
                cvToHex(uintCV(Number(amountIn))),
                cvToHex(uintCV(Number(reserveIn))),
                cvToHex(uintCV(Number(reserveOut))),
                cvToHex(swapFee),
                // cvToHex(protocolFee),
            ]
        });
        const val = parseFloat(hexToCV(result.data.result).value.value);
        return val;
    }

    async getAmountIn(amountOut, reserveIn, reserveOut, swapFee) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        const address = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
        amountOut = Number(amountOut).toFixed(0);
        const result = await this.contractService.callReadOnly('get-amount-in', {
            sender: address,
            "arguments": [
                cvToHex(uintCV(Number(amountOut))),
                cvToHex(uintCV(Number(reserveIn))),
                cvToHex(uintCV(Number(reserveOut))),
                cvToHex(swapFee),
            ]
        });
        return Number(hexToCV(result.data.result).value.value);
    }

    async quote(amountIn, reserveIn, reserveOut) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        const address = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
        const result = await this.contractService.callReadOnly('quote', {
            sender: address,
            "arguments": [
                cvToHex(uintCV(Number(amountIn))),
                cvToHex(uintCV(Number(reserveIn))),
                cvToHex(uintCV(Number(reserveOut))),
            ]
        });
        return Number(hexToCV(result.data.result).value.value);
    }
}

export default (new LibraryContractService());