import { sum, uniqueId } from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import axios from "axios";
import { stringify, toggleBodyScroll, velarToast } from "../helper";
import { toast } from "react-toastify";
import { Config } from "../config";
import { SuccessfulModal } from "../modals/SuccessfulModal";
import { FarmDistributorContractService as FarmDistributorContract } from "../services/FarmDistributorContractService";
// import FarmCoreContractService from "../services/FarmCoreContractService";
import { FarmCoreContractService as FarmCoreContract } from '../services/FarmCoreContractService';

const BlockInfo = (props) => {
  const { block, index, settings } = props;
  const [ blockInfo, setBlockInfo ] = useState(block);
  const [state, setState] = useState({ loader: false, successfulModal: false,  details: false, id: uniqueId() })
  const firstEpoch = blockInfo.epochs[0];
  const lastEpoch = blockInfo.epochs.at(-1);
  const epouchs = blockInfo.epochs.length > 1 ? "epochs " + firstEpoch + ".." + lastEpoch : "epoch " + firstEpoch;
  // const FarmCoreContractService = new FarmCoreContract(settings.poolInfo.contracts['farming-core']);
  const FarmDistributorContractService = new FarmDistributorContract(settings.poolInfo.contracts.farm['farming-distributor']);

  const claimRewards = async () => {
    setState({ ...state, loader: true });
    const result = await FarmDistributorContractService.distributeBlocks(blockInfo.epochs, blockInfo);
    setState({ ...state, successfulModal: true });
    velarToast(<div>
        <div className="title">Claiming Rewards from { epouchs }</div>
        <div className="timestamp">Less than 25 sec ago</div>
        <a className="link" target="_blank" href={`${Config.url}txid/${result.txId}?chain=${Config.name}`}>
            View on explorer
            <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
        </a>
    </div>);
  }

  const getRewardData = async () => {
    const reward = await FarmDistributorContractService.getReward(blockInfo.epoch);
    const hasRewards = (Number(reward.value.data.amt.value) > 0 || Number(reward.value.data.amt.value)) > 0;
    // const startBlockNumer = blockInfo.startBlockNumer;
    // const endBlockNumer = blockInfo.end;

    const revShareStaked = Number(reward.value.data.share.data.staked.value);
    const revShareTotal = Number(reward.value.data.share.data.total.value);
    const revAmount = Number(reward.value.data.amt.value);

    let revShare =  ((revShareStaked / revShareTotal) * 100).toFixed(3);
    if (isNaN(revShare)) {
      revShare = 0;
    }
    setState({ ...state, loader: false, details: true });
    setBlockInfo({ ...blockInfo, revShare, hasRewards, revAmount, fetched: true })
  }

  return (
    <div key={index} className="block-info mb-5">
      <div className="block-details" data-accordion="collapse">
        <span style={{ color: '#999', fontSize: 14 }}>Epoch { blockInfo.epoch }</span>
        <button
          onClick={() => {
            if (blockInfo.fetched) {
              setState({ ...state, details: !state.details, loader: false })
            } else {
              getRewardData().then(() => {});
              setState({ ...state, details: !state.details, loader: true })
            }
          }}
          type="button"
          className="block-btn text-pink-100 font-bold text-lg flex items-center justify-between w-full"
          data-accordion-target={"#" + state.id}
          ariaaria-expanded="true"
          aria-controls="collapsible-details"
        >
          <span className="title">{ block.start }</span>
          <div className="dotted-line"></div>
          <span className="title">End Block { blockInfo.end }</span>
          <div>
          <svg
            data-accordion-icon
            className={
              "w-6 h-6 shrink-0 " + (state.details && "rotate-180")
            }
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          </div>
        </button>
        { state.loader ? (
        <div className="p-5">
          <Loader />
        </div>
        ) : (
          <>
          <div id={state.id} className={state.details ? "details" : "details hidden"}>
            <div className="flex items-center justify-between mb-3">
              <div className="key direction-row flex items-center">
                <p className="text text-white">Rewards Share</p>
                <img
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Rewards Share"}
                  className="icon hand w-[15px] ml-2"
                  src="/assets/icons/icon-help-white.svg"
                  alt=""
                />
              </div>
              <p className="value text-white">{blockInfo.revShare} %</p>
            </div>
            {/* <div className="flex items-center justify-between mb-3">
              <div className="key direction-row flex items-center">
                <p className="text text-white">IDO Tickets</p>
                <img
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Rewards Share"}
                  className="icon hand w-[15px] ml-2"
                  src="/assets/icons/icon-help-white.svg"
                  alt=""
                />
              </div>
              <p className="value text-white">04</p>
            </div> */}
            { blockInfo.hasRewards ? (
              <button onClick={claimRewards} style={{ color: '#f89d33' }} className="btn btn-rewards">Claim Rewards</button>
            ) : (
              <button className="cursor-default btn btn-rewards">No Rewards found</button>
            ) }
          </div>
          </>
        ) }
      </div>
      { state.successfulModal && (
        <SuccessfulModal
            forType={'rewards'}
            state={state}
            message={`Rewards Claimed from ${epouchs}`}
            onClose={() => {
                setState({ ...state, successfulModal: false });
                toggleBodyScroll(false);
                props.get && props.get();
            }
        } />
      )}
    </div>
  )
}

export const FarmMyRewards = (props) => {
  const { currentEpoch, settings } = props;
  const dispatch = useDispatch();
  const [ loader, setLoader ] = useState(false);
  const [ blocks, setBlocks ] = useState([]);
  const auth = useSelector((x) => x.auth);

  const FarmCoreContractService = new FarmCoreContract(settings.poolInfo.contracts.farm['farming-core']);
  const FarmDistributorContractService = new FarmDistributorContract(settings.poolInfo.contracts.farm['farming-distributor']);
  const start_epoch = settings.poolInfo.contracts.farm.start_epoch || 0;

  useEffect(() => {
    if (String(props.currentEpoch).trim().length > 0 && Number(props.currentEpoch) > -1) {
      init();
    }
  }, [props.currentEpoch])

  const init = async () => {
    try {
      setLoader(true);
      const epochsRequests = [];
      const epochStart = [];
      const epochEnd = [];

      const start = await FarmCoreContractService.getCalcEpochStart(start_epoch);
      const EpochRange = 199;
      const epochStartRes = [start];
      const epochEndRes = [start + EpochRange];
      const epochs = [];
      let indexer = 0;
      let startLoop = settings.poolInfo.symbol === 'wSTX-VELAR' ? currentEpoch - start_epoch : start_epoch;

      for (let i = start_epoch; i < currentEpoch; i++) {
        if (i === (start_epoch)) {
          epochStartRes.push((epochStartRes[indexer] + EpochRange) + 1);
          epochEndRes.push((epochEndRes[indexer] + EpochRange) + 1);
        } else {
          epochStartRes.push((epochEndRes[indexer] + 1));
          epochEndRes.push((epochEndRes[indexer] + EpochRange) + 1);
        }

        epochs.push(i);
        indexer++;

        // epochEnd.push(FarmCoreContractService.getCalcEpochEnd(i));
        // epochStart.push(FarmCoreContractService.getCalcEpochStart(i));
        // epochsRequests.push(FarmDistributorContractService.getReward(i));
      }

      const rewards = await axios.all(epochsRequests);
      // const epochStartRes = await axios.all(epochStart);
      // const epochEndRes = await axios.all(epochEnd);

      const _blocks = [];
      let startEpoch = start_epoch;
      epochs.chunk(1).forEach((reward, index) => {        
        const hasRewards = false; //reward.find((x) => Number(x.value.data.amt.value) > 0 || Number(x.value.data.amt.value) > 0);
        const startBlockNumer = epochStartRes[index];
        const endBlockNumer = epochEndRes[index];
        const revShareStaked = 0; //reward.map((x) => Number(x.value.data.share.data.staked.value));
        const revShareTotal = 0; //reward.map((x) => Number(x.value.data.share.data.total.value));
        let revShare =  ((sum(revShareStaked) / sum(revShareTotal)) * 100).toFixed(3);
        if (isNaN(revShare)) {
          revShare = 0;
        }

        _blocks.push(
          { revShare, epoch: startEpoch, epochs: [startEpoch], startBlockNumer: startBlockNumer, start: `Start Block ${startBlockNumer}`, end: endBlockNumer, hasRewards }
          // { epochs: [startEpoch, startEpoch + 1], start: `Epoch ${startEpoch}`, end: startEpoch + 1, hasRewards }
        )
        startEpoch += 1;
      })
      setBlocks([ ..._blocks ])
      setLoader(false);      
    } catch (error) {
      console.log(error);
      setLoader(false);      
    }
  }

  return (
    <div className="my-rewards">
      { loader ? (
        <div className="p-5 m-5">
          <Loader />
        </div>
      ) : (
        <div className="block-list">
          {blocks.map((block, index) => {
            return <BlockInfo settings={settings} get={init} key={index} {...props} block={block} index={index} />
          })}
        </div>
      )}
      {/* <button className="btn btn-warning">Claim all rewards</button> */}
    </div>
  );
};
