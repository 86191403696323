import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { commarize, getTokenBasedExcatValue } from '../../helper';
import ExchangeService from '../../services/ExchangeService';

export default function ListView(props) {
    const auth = useSelector((x) => x.auth);
    const swap = useSelector((x) => x.swap);
    const { liquidities } = props;

    return (
        <div className="pool-list">
            <div className="overflow-x-auto">
                <table className='pool-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>TVL</th>
                            <th>Volume (24h)</th>
                            <th>Fees (24h)</th>
                            <th>APR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {liquidities.map((item, index) => {
                            const reserve0 = item?.blockchain?.reserve0?.value || 0;
                            const reserve1 = item?.blockchain?.reserve1?.value || 0;
                            let lpTokenBalance = Number(item.balance);
                            const stats = item.stats || {};
                            // let balance0 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve0, item.token0Symbol), item.token0Symbol);
                            // let balance1 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve1, item.token1Symbol), item.token1Symbol);
                            // let dollarBalance = commarize(Number(balance0) + Number(balance1));
                            return (
                            <tr key={index}>
                                <td>
                                    <Link to={`/pool/${item.lpTokenSymbol}`} className="conversion">
                                        <div className="icons">
                                            <img
                                                className="z-6"
                                                src={item.token0ImageUrl}
                                                alt="coin two" />
                                            <img
                                                className="z-7"
                                                src={item.token1ImageUrl}
                                                alt="coin one" />
                                        </div>
                                        <div className="coins">{ item.name }</div>
                                        <div className="fee">0.3%</div>
                                    </Link>
                                </td>
                                <td>{ stats.tvl.value ? '$' + commarize(stats.tvl.value, 2) : '-' }</td>
                                <td>${ commarize(stats.volume?.value, 6) || '--' }</td>
                                {/* { commarize(Number(item.totalSupply))}</td> */}
                                <td>${ commarize(stats.fees?.value, 6) || '-' }</td>
                                <td>
                                    { stats.apr?.value ? (
                                        commarize(stats.apr?.value) + '%'
                                    ) : (
                                        '--'
                                    ) }
                                    {/* <div className="flex items-center">
                                        <img className='mr-1.5' src="/assets/icons/fire.svg" alt="fire" />
                                        <span>10.95%</span>
                                    </div> */}
                                </td>
                            </tr>
                        )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
