import React from 'react';
import { WalletState } from './Wallet';

export const WalletTabs = (props) => {
    const { state, setState }  = props;
    return (
        <div className='sidebar-tabs'>
            <button className={(state.tab===WalletState.Token ? 'active ': '') + 'tab'} onClick={() => setState({ ...state, tab: WalletState.Token })}>Token</button>
            <button className={(state.tab===WalletState.Pool ? 'active ': '') + 'tab'} onClick={() => setState({ ...state, tab: WalletState.Pool })}>Pool</button>
            <button className={(state.tab===WalletState.Activity ? 'active ': '') + 'tab'} onClick={() => setState({ ...state, tab: WalletState.Activity })}>Activity</button>
        </div>
    )
}