import React from 'react';
import { useSelector } from 'react-redux';
import { commarize, getRandomColor, getTokenBasedExcatValue, getTokenDecimals, ifNanGiveZero, stringToColour } from '../../helper';
import Loader from '../Loader';
import ExchangeService from '../../services/ExchangeService';
import { cloneDeep } from 'lodash';
import { AppConstants } from '../../constants';

export const WalletToken = (props) => {
    const { state } = props;
    const auth = useSelector((x) => x.auth);
    const pool = useSelector((x) => x.pool);
    const swap = useSelector((x) => x.swap);
    const tokens = swap?.tokens?.message || [];

    return (
        <div className='sidebar-tab-content token-content' style={{ maxHeight: state.isMobile ? 350 : 'auto' }}>
            { (auth.info.loader || pool.poolsLoader || swap.tokens.loader) && (
                <div className="loader-inline ml-3 flex mt-10 justify-center items-center text-center">
                     <Loader width={45} />
                </div>
            )}
            {[...auth.coins,  ...(auth.info?.tokens || [])]?.filter((token) => {
                token = cloneDeep(token);
                if (token.coin) {
                    token.symbol = token.name === 'stx' ? 'wstx' : token.name;
                    return token;
                } else if (tokens.find((x) => x.contractAddress === token.contract)) {
                    return token;
                } else if (pool.pools.message.find((x) => x.lpTokenContractAddress === token.contract)) {
                    return token;
                }
            }).filter(Boolean).map((token, index) => {
                const lpToken = pool.pools.message.find((x) => x.lpTokenContractAddress === token.contract);
                let percent_change_24h = 0;
                let found = tokens.find((x) => x.contractAddress === token.contract);
                if (found?.percent_change_24h) {
                    percent_change_24h = Number(found.percent_change_24h)
                }
                let b = token.str_balance;
                let symbol = token.name;
                if (found) {
                    symbol = found.symbol;
                }
                let dollarValue = 0;
                let name = token.name;
                if (token.coin) {
                    let found = tokens.find((x) => x.symbol?.toLowerCase() === token.symbol?.toLowerCase());
                    if (found?.percent_change_24h) {
                        percent_change_24h = Number(found.percent_change_24h).toFixed(3);
                    }
                    dollarValue = ExchangeService.getTokenDollarPrice(token.balance, token.symbol?.toLowerCase(), true, 3);
                } else if (lpToken) {
                    let v1 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(lpToken?.blockchain?.my?.amt0, lpToken.token0Symbol), lpToken.token0Symbol);
                    let v2 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(lpToken?.blockchain?.my?.amt1, lpToken.token1Symbol), lpToken.token1Symbol); //, token.token1Symbol, true, 3);
                    dollarValue =  ifNanGiveZero(parseFloat(Number(v1) + Number(v2))).toFixed(3);
                    name = lpToken.name;
                } else if (token.token) {
                    name = found.name;
                    b = commarize(Number(token.balance) / found.tokenDecimalNum, 5);
                    dollarValue = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(token.balance, found.symbol), found.symbol, true, 3);
                    if (found.imageUrl) {
                        token = { imageUrl: found.imageUrl, ...token};
                    }
                }
                return (
                    <div key={index} className='item items-center'>
                        <div className='left' style={{ width: '50%' }}>
                            { token.imageUrl ? (
                            <div style={{ border: '0.2px solid #333' }}  className='flex icon w-10 h-10 items-center justify-center text-white'>
                                <img src={token.imageUrl} />
                            </div>
                            ) : (
                            <div style={{ backgroundColor: stringToColour(token.contract || ''), border: '1px solid #444' }} className='flex icon w-10 h-10 items-center justify-center text-white'>
                                {name.substr(0, 1).toUpperCase() }
                            </div>
                            ) }
                            <div style={{ width: '50%' }}>
                                <p 
                                 className='text-upr' title={token.name}>{ name?.length > 10 ? name.substr(0, 10) + ' ...' : name }</p>
                                <p className='text-lwr' title={symbol}>{ b } { symbol?.length > 10 ? symbol.substr(0, 10) + ' ...' : symbol }</p>
                            </div>
                        </div>
                        <div className='right'>
                            <p className='text-upr'>${dollarValue}</p>
                            {/* <p className='text-upr'>${ ExchangeService.getTokenDollarPrice(token.balance, token.symbol) }</p> */}
                            <div className='progress'>
                                {percent_change_24h > 0 && (
                                    <>
                                        <img src='/assets/icons/icon-up-arrow.svg' alt="" />
                                        <p className='text'>{ Math.abs(percent_change_24h) }%</p>
                                    </>
                                )}
                                {percent_change_24h < 0 && (
                                    <>
                                        <img src='/assets/icons/icon-loss.svg' alt="" />
                                        <p className='text'>{ Math.abs(percent_change_24h) }%</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}