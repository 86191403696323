import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useOutsideAlerter, useOutsideAlerterSingle } from '../../hooks/useOutsideAlerter';

export default function Search(props) {
    const [state, setState] = useState({ showSearchResults: false })
    const [ search, setSearch ] = useState('')
    const [ selected, setSelected ] = useState(props.searchSelected ? [props.searchSelected] : []);
    const searchRef = useRef(null);
    let searchContentRef = useRef(null);
    const data = props.data;
    
    useOutsideAlerterSingle(searchContentRef, state, () => {
        if (Array.isArray(selected) && selected.length > 0) {
            setSearch(selected[0].name);
        }
        setState({ ...state, showSearchResults: false })
    })

    const onSelect = (item) => {
        setSelected([item]);
        setState({ ...state, showSearchResults: false });
        setSearch(item.name);
        props.onChange && props.onChange(item);
        return;
        const index = selected.findIndex((x) => x.symbol === item.symbol);
        if (index > -1) {
            // selected.splice(index, 1);
            // setSelected([...selected]);
            setSelected([item]);
        } else {
            setSelected([item]);
            // selected.push(item);
            // setSelected([...selected]);
        }
    }

    return (
        <>
            <form className="relative inline-block">
                <div className='search-box relative'>
                    <input
                        ref={searchRef}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onClick={(e) => {
                            setSearch('');
                            setState({ ...state,  showSearchResults: true })
                        }}
                        value={search}
                        type="text"
                        autoComplete='off'
                        autoCorrect='off'
                        autoCapitalize='off'
                        style={{ color: '#aaa'}}
                        spellCheck="false"
                        className="header-searchbar"
                        placeholder="Search token, token pairs etc.."
                    />
                    { search.length > 0 && (
                        <div onClick={(e) => {
                            setSearch('');
                            setSelected([]);
                            props.onChange && props.onChange(null);
                        }} className='delete-icon'>x</div>
                    ) }
                </div>
                { state.showSearchResults && (
                    <div ref={searchContentRef} className="absolute z-50 mt-1 w-full p-2 shadow-lg bg-dull-200/80 backdrop-blur-sm px-4 border-[1px] border-dull-100/20 rounded-lg max-h-56 overflow-y-auto">
                        <span className='flex justify-start text-gray text-sm font-bold'>Tokens</span>
                        {data.filter((x) => { 
                            return x?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1
                            }).map((item, index) => {
                            return (
                                <div onClick={(e) => onSelect(item)} key={index} className="my-2 flex justify-start text-dull-100 cursor-pointer">
                                    <div className="flex items-start text-white text-base">
                                        <img
                                            style={{ width: 32, height: 32, borderRadius: '100%', border: '1px solid #222' }}
                                            src={item.imageUrl}
                                            className="mr-2 mt-1"
                                            alt="coin"
                                        />
                                        <div className="flex flex-col items-start bg-red-s">
                                            { item.name }
                                            <p className="text-gray font-bold text-xs"> { item.symbol } </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* <Link to="#">
                            <div className="my-2 flex justify-start text-dull-100 cursor-pointer hover:bg-opacity-10">
                                <div className="flex items-start text-white text-base">
                                    <img
                                        style={{ width: 32, height: 32, borderRadius: '100%', border: '1px solid #222' }}
                                        src="https://raw.githubusercontent.com/velar-be/asset-hosting/main/velar.jpg"
                                        className="mr-2 mt-1"
                                        alt="coin"
                                    />
                                    <div className="flex flex-col items-start bg-red-s">
                                        Velar
                                        <p className="text-gray font-bold text-xs"> VELAR </p>
                                    </div>
                                </div>
                            </div>
                        </Link> */}
                    </div>
                ) }
            </form>
        </>


    )
}
